import React, { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { useOnScreen } from 'hooks/useOnScreen';
import { UserService } from 'services/user';

import { agreementTypes } from './helper';

type ServiceAgreementProps = {
  onAccept: (logoUrl: string) => void;
  onDeny: () => void;
  loading?: boolean;
};

export function ServiceAgreement({
  onAccept,
  onDeny,
  loading,
}: ServiceAgreementProps) {
  const [bottom, setBottom] = useState(false);
  const [showBottomError, setShowBottomError] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [searchParams] = useSearchParams();
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const token = searchParams.get('token') ?? '';
  const practiceDetailsQuery = useQuery(
    [QueryKeys.PatientPracticeDetails],
    () => UserService.getOnboardPractice({ token })
  );
  const isConciergePractice =
    practiceDetailsQuery.data?.data.inviteToPractice.isConciergePractice;
  const isShippingCostCovered =
    practiceDetailsQuery.data?.data.inviteToPractice.isShippingCostCovered;
  const isMedicationCostCovered =
    practiceDetailsQuery.data?.data.inviteToPractice.isMedicationCostCovered;

  useEffect(() => {
    if (isVisible) {
      setBottom(true);
    }
  }, [isVisible]);

  return (
    <div className="flex h-screen w-screen flex-col items-center bg-background-dark py-4 px-4">
      <div
        className={classNames(
          'flex h-[90%] flex-col  overflow-y-auto bg-background-dark sm:w-[26rem]  ',
          {
            'overflow-y-hidden': practiceDetailsQuery.isLoading,
          }
        )}>
        <Typography variant="h4">
          BRITE™ Membership Services Agreement
        </Typography>
        {practiceDetailsQuery.isLoading ? (
          <div className="flex grow items-center justify-center">
            <LoadingSpinner className="  h-10 w-10 text-primary-light " />{' '}
          </div>
        ) : (
          <Typography className="mt-2">
            {' '}
            {isConciergePractice === false
              ? agreementTypes.nonConcierge
              : isConciergePractice &&
                isMedicationCostCovered &&
                isShippingCostCovered
              ? agreementTypes.conciergeSubscriptionMedicationAndShip
              : isConciergePractice && isShippingCostCovered
              ? agreementTypes.conciergeSubscriptionAndShipping
              : isConciergePractice && isMedicationCostCovered
              ? agreementTypes.conciergeSubscriptionAndRxs
              : isConciergePractice &&
                !isShippingCostCovered &&
                !isMedicationCostCovered
              ? agreementTypes.conciergeSubscription
              : ''}
          </Typography>
        )}

        <div
          className={classNames('m-2 p-2', {
            hidden: practiceDetailsQuery.isLoading,
          })}
          ref={ref}
        />
      </div>
      <div className="mb-8 flex w-full gap-12 sm:w-[26rem] ">
        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            setShowDenyModal(true);
          }}
          className="mt-4 ">
          Deny
        </Button>
        <Button
          disabled={practiceDetailsQuery.isLoading}
          loading={loading}
          fullWidth
          onClick={() => {
            if (bottom) {
              onAccept(
                practiceDetailsQuery.data?.data.inviteToPractice.logoUrl ?? ''
              );
            } else {
              setShowBottomError(true);
            }
          }}
          className="mt-4 ">
          Accept
        </Button>
      </div>
      <Modal
        open={showBottomError}
        closeModal={() => {
          //
        }}>
        <div className="col flex flex-col items-center">
          <Typography>
            Please read the entire agreement and then “Accept” or “Deny” it.
          </Typography>
          <Button
            onClick={() => setShowBottomError(false)}
            className="mt-4 w-20">
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        open={showDenyModal}
        closeModal={() => {
          //
        }}>
        <div className="col flex flex-col items-center">
          <Typography>
            Your Brite account won’t be created until you accept the agreement.
          </Typography>
          <Typography className="mt-1">
            Are you sure you want to “Deny”
          </Typography>
          <div className="flex gap-4">
            <Button
              variant="outlined"
              onClick={() => setShowDenyModal(false)}
              className="mt-4 w-20">
              No
            </Button>{' '}
            <Button
              onClick={() => {
                setShowDenyModal(false);
                onDeny();
              }}
              className="mt-4 w-20">
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
