import { DeaLicenseProvider } from '../AddLicenseDea/DeaLicensesContext';
import { NpiDeaLicenseDetails } from './NpiDeaLicenseDetails';

export function NPIDEAAndLicense() {
  return (
    <DeaLicenseProvider>
      <NpiDeaLicenseDetails />
    </DeaLicenseProvider>
  );
}
