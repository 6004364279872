import { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';

import AppStoreLogo from '../../../assets/images/app-store.png';
import briteQR from '../../../assets/images/briteQR.png';
import PlayStoreLogo from '../../../assets/images/google-play-badge.png';
import LogoWhite from '../../../assets/images/White Logo.png';

export function PatientApp() {
  const navigate = useNavigate();

  useEffect(() => {
    window.onpopstate = () => {
      navigate('/login');
    };
  }, [navigate]);

  return (
    <div className="flex h-screen w-screen justify-center bg-background-dark">
      <div className=" flex w-full flex-col items-center justify-center p-4 sm:w-2/4">
        <Helmet>
          <meta name="apple-itunes-app" content="app-id=6448186350" />
          <meta name="google-play-app" content="app-id=com.brite.live" />
        </Helmet>

        <img
          src={LogoWhite}
          alt="Logo"
          className="h-60 overflow-hidden md:h-96"
        />

        <Typography
          align="center"
          color="white"
          className="-mt-12 text-xl md:text-xl lg:text-2xl"
          variant="h1">
          Please download the app from App store or Play store.
        </Typography>
        <Typography
          align="center"
          color="white"
          className=" mt-2 text-xl md:text-xl lg:text-2xl"
          variant="subtitle3">
          Scan to download the app.
        </Typography>

        <figure className="relative mt-8 h-40 w-40 lg:h-48 lg:w-48 ">
          <img
            className="h-full w-full object-contain"
            src={briteQR}
            alt="Error Svg"
          />
        </figure>
        <div className=" mt-6 inline-flex w-full items-center justify-center">
          <hr className="my-8 h-1 w-64 rounded border-0 bg-background-light" />
          <div className=" text- absolute left-1/2 -translate-x-1/2 bg-background-dark  px-4">
            <Typography variant="subtitle3" color="textPrimary">
              OR
            </Typography>
          </div>
        </div>

        <div className="flex items-center ">
          <div className="flex flex-col">
            <a
              href="https://apps.apple.com/in/app/brite-live/id6448186350"
              target="_blank"
              rel="noreferrer">
              <img className="f w-40 sm:w-60" src={AppStoreLogo} alt="Logo" />
            </a>
          </div>
          <div className="flex flex-col">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.brite.live"
              rel="noreferrer">
              <img className="f w-40 sm:w-60" src={PlayStoreLogo} alt="Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
