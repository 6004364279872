import { Button } from 'components/common/Button/Button';

import errorSvg from '../../../assets/images/computer.png';

export function ErrorFallbackComponent() {
  return (
    <div className="relative grid h-full min-h-screen w-full shrink place-content-center bg-background-dark text-center">
      <figure className="relative mb-[30px] max-h-[15rem] max-w-[15rem] shrink">
        <img
          className="h-full w-full object-contain"
          src={errorSvg}
          alt="Error Svg"
        />
      </figure>
      <p className="mb-2 text-5xl font-semibold text-background-contrastText">
        Oh no!
      </p>
      <p className="text-xl text-background-contrastText">
        Something went wrong.
      </p>
      <Button
        className="mx-auto mt-8"
        type="button"
        onClick={() => window.location.reload()}>
        Reload
      </Button>
    </div>
  );
}
