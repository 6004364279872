/* eslint-disable @typescript-eslint/no-explicit-any */

import toast from 'react-hot-toast';

import { tabs } from 'pages/auth/provider-onboard/ProviderOnboard2';
import { FieldError } from 'types/common';

const findNavigationTab = (obj: any, searchKey: string): string => {
  const keys = Object.keys(obj);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    if (key === searchKey) {
      return key;
    }
    const value = obj[key];
    if (typeof value === 'object' && value !== null) {
      const subKeys = Object.keys(value);
      if (subKeys.includes(searchKey)) {
        return key; // Found the parent key
      }
      const parentKey = findNavigationTab(value, searchKey);
      if (parentKey) {
        return key;
      }
    }
  }

  return '';
};

export const getNavigationTab = (obj: any, searchKey: string): string => {
  const responseKey = findNavigationTab(obj, searchKey);

  if (responseKey) {
    const res = tabs.find((item) => item.key === responseKey);

    return res?.link || '';
  }

  return '';
};

export const getSearchKeyNameFromError = (errorFieldObject: {
  [x: string]: FieldError;
}) => {
  if (Object.keys(errorFieldObject).length) {
    const key = Object.keys(errorFieldObject)[0];
    if (Object.keys(errorFieldObject).includes('phone')) {
      return {
        phone: errorFieldObject[key].msg,
        searchKey: 'phoneNumber',
      };
    }
    if (Object.keys(errorFieldObject).includes('provider_suffix')) {
      return {
        providerTitle: errorFieldObject[key].msg,
        searchKey: 'providerTitle',
      };
    }
    const errorFieldKey = Object.keys(errorFieldObject)[0].replace(
      /(_\w)/g,
      (match) => match[1].toUpperCase()
    );

    toast.error(errorFieldObject[key].msg);

    return {
      [errorFieldKey]: errorFieldObject[key].msg,
      searchKey: errorFieldKey,
    };
  }

  return null;
};
