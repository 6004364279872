import classNames from 'classnames';

import { SkeletonContent } from '../SkeletonContent/SkeletonContent';
import { TableSkeletonProps } from './types';

export function TableSkeleton<TItem>({
  recordsPerPage,
  columns,
}: TableSkeletonProps<TItem>) {
  return (
    <>
      {[...Array(recordsPerPage)].map((i, index) => (
        // eslint-disable-next-line
        <tr key={index} className="bg-background-main">
          {columns.map((item) => (
            <td
              key={item.title}
              className={classNames('px-6  py-3 ', item.skeletonClassNames)}>
              <SkeletonContent
                containerClassName="w-full"
                isLoading
                layout={[
                  {
                    className: 'w-full h-4 my-0.5 gap-5',
                  },
                ]}
              />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
