import React from 'react';

// eslint-disable-next-line import/extensions
import LogoGreen from 'assets/images/LOGO.png';
import { Typography } from 'components/common/Typography/Typography';

export function PrivacyPolicy() {
  return (
    <div className="w-screen justify-center bg-background-dark p-10  ">
      <div className="flex justify-center">
        <img src={LogoGreen} alt="Logo" style={{ maxHeight: '20vh' }} />
      </div>
      <Typography variant="h2" className="mt-8 mb-8 justify-center">
        Privacy Policy{' '}
      </Typography>
      <Typography variant="subtitle2">Updated 06/21/2023</Typography>
      <Typography variant="subtitle2">
        The Institute of Bioidentical Medicine (IOBIM) values its users’
        privacy. This Privacy Policy (“Policy”) will help you understand how we
        collect and use personal information from those who visit our website or
        make use of our online facilities and services, and what we will and
        will not do with the information we collect. Our Policy has been
        designed and created to ensure those affiliated with The Institute of
        Bioidentical Medicine INC of our commitment and realization of our
        obligation not only to meet, but to exceed, most existing privacy
        standards. We reserve the right to make changes to this Policy at any
        given time. If you want to make sure that you are up to date with the
        latest changes, we advise you to frequently visit this page. If at any
        point in time The Institute of Bioidentical Medicine, INC decides to
        make use of any personally identifiable information on file, in a manner
        vastly different from that which was stated when this information was
        initially collected, the user or users shall be promptly notified by
        email. Users at that time shall have the option as to whether to permit
        the use of their information in this separate manner. This Policy
        applies to The Institute of Bioidentical Medicine, INC, and it governs
        any and all data collection and usage by us. Through the use of
        www.menopausemethod.com, you are therefore consenting to the data
        collection procedures expressed in this Policy. Please note that this
        Policy does not govern the collection and use of information by
        companies that The Institute of Bioidentical Medicine, INC does not
        control, nor by individuals not employed or managed by us. If you visit
        a website that we mention or link to, be sure to review its privacy
        policy before providing the site with information. It is highly
        recommended and suggested that you review the privacy policies and
        statements of any website you choose to use or frequent to better
        understand the way in which websites garner, make use of and share the
        information collected.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        SPECIFICALLY, THIS POLICY WILL INFORM YOU OF THE FOLLOWING
      </Typography>
      <Typography variant="subtitle2">
        1. What personally identifiable information is collected from you
        through our website; <br /> 2. Why we collect personally identifiable
        information and the legal basis for such collection; <br />
        3. How we use the collected information and with whom it may be shared;{' '}
        <br />
        4. What choices are available to you regarding the use of your data;{' '}
        <br /> 5. The security procedures in place to protect the misuse of your
        information.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        INFORMATION WE COLLECT
      </Typography>
      <Typography variant="subtitle2">
        It is always up to you whether to disclose personally identifiable
        information to us, although if you elect not to do so, we reserve the
        right not to register you as a user or provide you with any products or
        services. This website collects various types of information, such as: ·
        <br />
        Voluntarily provided information which may include your name, address,
        email address, billing and/or credit card information etc. which may be
        used when you purchase products and/or services and to deliver the
        services you have requested. · Information automatically collected when
        visiting our website, which may include cookies, third party tracking
        technologies and server logs.
        <br /> In addition, The Institute of Bioidentical Medicine, INC may have
        the occasion to collect non-personal anonymous demographic information,
        such as age, gender, household income, political affiliation, race and
        religion, as well as the type of browser you are using, IP address, or
        type of operating system, which will assist us in providing and
        maintaining superior quality service.
        <br /> The Institute of Bioidentical Medicine, INC may also deem it
        necessary, from time to time, to follow websites that our users may
        frequent to glean what types of services and products may be the most
        popular to customers or the general public. Please rest assured that
        this site will only collect personal information that you knowingly and
        willingly provide to us by way of surveys, completed membership forms,
        and emails. It is the intent of this site to use personal information
        only for the purpose for which it was requested, and any additional uses
        specifically provided for on this Policy.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-1">
        WHY WE COLLECT INFORMATION AND FOR HOW LONG
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        WE ARE COLLECTING YOUR DATA FOR SEVERAL REASONS:
      </Typography>
      <Typography variant="subtitle2">
        · To better understand your needs and provide you with the services you
        have requested;
        <br /> · To fulfill our legitimate interest in improving our services
        and products;
        <br /> · To send you promotional emails containing information we think
        you may like when we have your consent to do so;
        <br /> · To contact you to fill out surveys or participate in other
        types of market research, when we have your consent to do so;
        <br /> · To customize our website according to your online behavior and
        personal preferences.
        <br />
        The data we collect from you will be stored for no longer than
        necessary. The length of time we retain said information will be
        determined based upon the following criteria: the length of time your
        personal information remains relevant; the length of time it is
        reasonable to keep records to demonstrate that we have fulfilled our
        duties and obligations; any limitation periods within which claims might
        be made; any retention periods prescribed by law or recommended by
        regulators, professional bodies or associations; the type of contract we
        have with you, the existence of your consent, and our legitimate
        interest in keeping such information as stated in this Policy.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        USE OF INFORMATION COLLECTED
      </Typography>
      <Typography variant="subtitle2">
        {' '}
        The Institute of Bioidentical Medicine, INC does not now, nor will it in
        the future, sell, rent or lease any of its customer lists and/or names
        to any third parties.
        <br />
        The Institute of Bioidentical Medicine, INC may collect and may make use
        of personal information to assist in the operation of our website and to
        ensure delivery of the services you need and request. At times, we may
        find it necessary to use personally identifiable information as a means
        to keep you informed of other possible products and/or services that may
        be available to you from www.menopausemethod.com
        <br />
        The Institute of Bioidentical Medicine, INC may also be in contact with
        you with regards to completing surveys and/or research questionnaires
        related to your opinion of current or potential future services that may
        be offered.
        <br />
        The Institute of Bioidentical Medicine, INC may feel it necessary, from
        time to time, to contact you on behalf of our other external business
        partners with regards to a potential new offer which may be of interest
        to you. If you consent or show interest in presented offers, then, at
        that time, specific identifiable information, such as name, email
        address and/or telephone number, may be shared with the third party. The
        Institute of Bioidentical Medicine, INC uses various third-party social
        media features including but not limited to Facebook, Instagram,
        Twitter, YouTube and other interactive programs. These may collect your
        IP address and require cookies to work properly. These services are
        governed by the privacy policies of the providers and are not within The
        Institute of Bioidentical Medicine, INC’s control.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        DISCLOSURE OF INFORMATION
      </Typography>

      <Typography variant="subtitle3">
        {' '}
        The Institute of Bioidentical Medicine, INC may not use or disclose the
        information provided by you except under the following circumstances:
        <br /> · as necessary to provide services or products you have ordered;
        <br /> · in other ways described in this Policy or to which you have
        otherwise consented;
        <br /> · in the aggregate with other information in such a way so that
        your identity cannot reasonably be determined;
        <br /> · as required by law, or in response to a subpoena or search
        warrant;
        <br /> · to outside auditors who have agreed to keep the information
        confidential;
        <br /> · as necessary to enforce the Terms of Service;
        <br /> · as necessary to maintain, safeguard and preserve all the rights
        and property of The Institute of Bioidentical Medicine, INC.
      </Typography>

      <Typography variant="h3" className="mt-5 mb-5">
        TEXT MESSAGE
      </Typography>
      <Typography variant="subtitle3">
        By providing your cell phone number, you have provided The Institute of
        Bioidentical Medicine with consent to send you text messages to provide
        you information about your account serviced by The Institute of
        Bioidentical Medicine regarding the services you have requested which
        may include but not be limited to assistance in completing your program,
        notification of transaction approval, payment reminders and
        notifications, servicing your account, and collection efforts. By
        providing this consent, you are authorizing The Institute of
        Bioidentical Medicine (collectively, “we”, “us” and “our”) to contact
        you using automatic telephone dialing systems, automatic texting
        systems, and artificial or prerecorded messages. By providing this
        consent, you have also represented that you are the owner or customary
        user of any cell phone number which you have provided to us. Your
        cellular provider’s Message & Data Rates May Apply.
        <br />
        You understand the text messages we send may be seen by anyone with
        access to your phone and you agree that we are not liable for anyone
        accessing such messages. Accordingly, you should take steps to safeguard
        your phone and your text messages if you want them to remain private.
        You agree to notify us immediately if you change your cell phone number,
        cease using the cell phone number you provided us, assign any
        non-cellular phone number you have provided us to a cellular service, or
        transfer your cell phone to another person.
        <br />
        Please be aware that our account management text message program does
        not currently provide for two-way communication, other than sending
        auto-responses if you text STOP or HELP as specified below. Also please
        be aware that account management text messages that we send you may come
        from different numbers.
      </Typography>

      <Typography variant="h3" className="mt-5 mb-5">
        BUSINESS TRANSFERS
      </Typography>
      <Typography variant="subtitle3">
        If The Institute of Bioidentical Medicine, or substantially all of its
        assets, were acquired, or in the unlikely event that The Institute of
        Bioidentical Medicine goes out of business or enters bankruptcy, user
        information would be one of the assets that is transferred or acquired
        by a third party. You acknowledge that such transfers may occur, and
        that any acquirer of The Institute of Bioidentical Medicine may continue
        to use your personal information as set forth in this policy.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        ADS
      </Typography>
      <Typography variant="subtitle3">
        Ads appearing on any of our websites may be delivered to users by
        advertising partners, who may set cookies. These cookies allow the ad
        server to recognize your computer each time they send you an online
        advertisement to compile information about you or others who use your
        computer. This information allows ad networks to, among other things,
        deliver targeted advertisements that they believe will be of most
        interest to you. This Privacy Policy covers the use of cookies by The
        Institute of Bioidentical Medicine and does not cover the use of cookies
        by any advertisers.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        UNSUBSCRIBE OR OPT-OUT
      </Typography>
      <Typography variant="subtitle3">
        All users and visitors to our website have the option to discontinue
        receiving communications from us by way of email or newsletters. To
        discontinue or unsubscribe from our website please send an email that
        you wish to unsubscribe to support@iobim.org. If you wish to unsubscribe
        or opt-out from any third-party websites, you must go to that specific
        website to unsubscribe or opt-out. The Institute of Bioidentical
        Medicine, INC will continue to adhere to this Policy with respect to any
        personal information previously collected.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        LINKS TO OTHER WEBSITES
      </Typography>
      <Typography variant="subtitle3">
        Our website does contain links to affiliates and other websites. The
        Institute of Bioidentical Medicine, INC does not claim nor accept
        responsibility for any privacy policies, practices and/or procedures of
        other such websites. Therefore, we encourage all users and visitors to
        be aware when they leave our website and to read the privacy statements
        of every website that collects personally identifiable information. This
        Privacy Policy Agreement applies only and solely to the information
        collected by our website.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        NOTICE TO EUROPEAN UNION USERS
      </Typography>
      <Typography variant="subtitle3">
        The Institute of Bioidentical Medicine, INC’s operations are located
        primarily in the United States. If you provide information to us, the
        information will be transferred out of the European Union (EU) and sent
        to the United States. (The adequacy decision on the EU-US Privacy became
        operational on August 1, 2016. This framework protects the fundamental
        rights of anyone in the EU whose personal data is transferred to the
        United States for commercial purposes. It allows the free transfer of
        data to companies that are certified in the US under the Privacy
        Shield.) By providing personal information to us, you are consenting to
        its storage and use as described in this Policy.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        YOUR RIGHTS AS A DATA SUBJECT
      </Typography>
      <Typography variant="subtitle3">
        Under the regulations of the General Data Protection Regulation (“GDPR”)
        of the EU you have certain rights as a Data Subject. These rights are as
        follows:
        <br /> · The right to be informed: this means we must inform you of how
        we intend to use your personal data and we do this through the terms of
        this Policy. <br />· The right of access: this means you have the right
        to request access to the data we hold about you and we must respond to
        those requests within one month. You can do this by sending an email to
        support@iobim.org. <br />· The right to rectification: this means that
        if you believe some of the data we hold is incorrect, you have the right
        to have it corrected. You can do this by logging into your account with
        us, or by sending us an email with your request.
        <br /> · The right to erasure: this means you can request that the
        information we hold be deleted, and we will comply unless we have a
        compelling reason not to, in which case you will be informed of the
        same. You can do this by sending an email to support@iobim.org.
        <br /> · The right to restrict processing: this means you can change
        your communication preferences or opt-out of certain communications. You
        can do this by sending an email to support@iobim.org.
        <br /> · The right of data portability: this means you can obtain and
        use the data we hold for your own purposes without explanation. If you
        wish to request a copy of your information, contact us at
        support@iobim.org.
        <br /> · The right to object: this means you can file a formal objection
        with us regarding our use of your information with regard to third
        parties, or its processing where our legal basis is our legitimate
        interest in it. To do this, please send an email to support@iobim.org.
        <br />
        In addition to the rights above, please rest assured that we will always
        aim to encrypt and anonymize your personal information whenever
        possible. We also have protocols in place in the unlikely event that we
        suffer a data breach and we will contact you if your personal
        information is ever at risk. For more details regarding our security
        protections see the section below or visit our website at
        www.menopausemethod.com.
      </Typography>

      <Typography variant="h3" className="mt-5 mb-5">
        SECURITY
      </Typography>
      <Typography variant="subtitle3">
        Looking for a lock icon in the address bar and looking for “https” at
        the beginning of the address of the webpage.
        <br /> While we use encryption to protect sensitive information
        transmitted online, we also protect your information offline. Only
        employees who need the information to perform a specific job (for
        example, billing or customer service) are granted access to personally
        identifiable information. The computers and servers in which we store
        personally identifiable information are kept in a secure environment.
        This is all done to prevent any loss, misuse, unauthorized access,
        disclosure or modification of the user’s personal information under our
        control.
        <br /> The company also uses Secure Socket Layer (SSL) for
        authentication and private communications to build users’ trust and
        confidence in the internet and website use by providing simple and
        secure access and communication of credit card and personal information.
        The website is also secured by VeriSign.
      </Typography>

      <Typography variant="h3" className="mt-5 mb-5">
        ACCEPTANCE OF TERMS
      </Typography>

      <Typography variant="subtitle3">
        By using this website, you are hereby accepting the terms and conditions
        stipulated within the Privacy Policy Agreement. If you are not in
        agreement with our terms and conditions, then you should refrain from
        further use of our sites. In addition, your continued use of our website
        following the posting of any updates or changes to our terms and
        conditions shall mean that you agree and accept such changes.
      </Typography>

      <Typography variant="h3" className="mt-5 mb-5">
        HIPAA NOTICE OF INFORMATION PRACTICES AND PRIVACY STATEMENT
      </Typography>

      <Typography variant="subtitle3"> February 1, 2020</Typography>

      <Typography variant="h3" className="mt-5 mb-5">
        HOW WE COLLECT INFORMATION ABOUT YOU
      </Typography>
      <Typography variant="subtitle3">
        {' '}
        The Institute of Bioidentical Medicine, INC and its employees and
        volunteers collect data through a variety of means including but not
        necessarily limited to letters, phone calls, emails, voicemails, and
        from the submission of applications that are either required by law or
        necessary to process applications or other requests for assistance
        through our organization.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        WHAT WE DO NOT DO WITH YOUR INFORMATION:
      </Typography>
      <Typography variant="subtitle3">
        {' '}
        Information about your financial situation and medical conditions and
        care that you provide to us in writing, via email, via website, on the
        phone (including information left on voicemails), contained in or
        attached to applications, or directly or indirectly given to us, is held
        in strictest confidence.
        <br /> We do not give out, exchange, barter, rent, sell, lend, or
        disseminate any information about applicants or clients who apply for or
        actually receive our services that are considered patient confidential,
        restricted by law, or specifically restricted by a patient/client in a
        signed HIPAA consent form.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        HOW WE DO USE YOUR INFORMATION:
      </Typography>
      <Typography variant="subtitle3">
        Information is only used as is reasonably necessary to process your
        application for The Institute of Bioidentical Medicine. If you apply or
        attempt to apply to receive assistance through us and provide
        information with the intent or purpose of fraud or that results in
        either an actual crime of fraud for any reason including willful or
        un-willful acts of negligence whether intended or not, or in any way
        demonstrates or indicates attempted fraud, your non-medical information
        can be given to legal authorities including police, investigators,
        courts, and/or attorneys or other legal professionals, as well as any
        other information as permitted by law.
      </Typography>
      <Typography variant="h3" className="mt-5 mb-5">
        LIMITED RIGHT TO USE NON-IDENTIFYING PERSONAL INFORMATION FROM
        BIOGRAPHIES, LETTERS, NOTES, AND OTHER SOURCES:
      </Typography>
      <Typography variant="subtitle3">
        Any pictures, videos, audio, stories, letters, biographies,
        correspondence, or thank you notes sent to us, used by us or recorded by
        us become the exclusive property of HCB. We reserve the right to use
        non-identifying information about our clients (those who receive
        services or goods from or through us) for promotional purposes that are
        directly related to our mission.
        <br /> Clients will not be compensated for use of this information and
        no identifying information (photos, addresses, phone numbers, contact
        information, last names or uniquely identifiable names) will be used
        without the client’s express advance permission.
        <br /> You may specifically request that NO information be used
        whatsoever for promotional purposes, but you must identify any requested
        restrictions in writing. We respect your right to privacy and assure you
        no identifying information or photos that you send to us will ever be
        publicly used without your direct or indirect consent.
      </Typography>

      <Typography variant="h3" className="mt-5 mb-5">
        HOW TO CONTACT US
      </Typography>
      <Typography variant="subtitle3">
        If you have any questions or concerns regarding the HIPAA Privacy Policy
        Agreement related to our website, please feel free to contact us at the
        following email, telephone number or mailing address.
        <br /> Email: support@iobim.org <br /> Telephone Number: 941-202-5957{' '}
        <br />
        Mailing Address: The Institute of Bioidentical Medicine, INC 1058 N.
        Tamiami Trail, Sarasota, FL, 34236
      </Typography>
    </div>
  );
}
