// MyDeaLicenseContext.js
import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { GetDeaLicenses } from 'types/onboard';

import { useOnboardProviderFormContext } from '../OnboardFormContext';

interface DeaLicenseContextType {
  deaLicenses: GetDeaLicenses[];
  handleDEALicenseData: (data: GetDeaLicenses) => void;
  handleDeleteDEALicenseData: (id: string, data: GetDeaLicenses[]) => void;
}

const DeaLicenseContext = createContext<DeaLicenseContextType | null>(null);

export const useDeaLicenseContext = () => {
  const context = useContext(DeaLicenseContext);
  if (!context) {
    throw new Error(
      'useDeaLicenseContext must be used within a DeaLicenseProvider'
    );
  }

  return context;
};

export function DeaLicenseProvider({ children }: { children: ReactNode }) {
  const { formState } = useOnboardProviderFormContext();
  const [deaLicenses, setDeaLicenses] = useState<GetDeaLicenses[]>(
    (formState.npiDeaLicense?.licensesDeas as unknown as GetDeaLicenses[]) || []
  );

  // Function to handle adding or updating dea licenses
  const handleDEALicenseData = (data: GetDeaLicenses) => {
    setDeaLicenses((prevLicenses) => {
      const tempData = [...prevLicenses];
      const index = tempData.findIndex((item) => item.stateId === data.stateId);

      if (index > -1) {
        tempData[index] = data;
      } else {
        tempData.push(data);
      }

      return tempData;
    });
  };

  const handleDeleteDEALicenseData = (
    id: string,
    deaLicensesData: GetDeaLicenses[]
  ) => {
    const index = deaLicensesData?.findIndex((item) => item.stateId === id);

    if (Number(index) > -1) {
      const tempSpecialities = [...(deaLicensesData || [])];
      tempSpecialities.splice(Number(index), 1);

      setDeaLicenses(tempSpecialities);
    }
  };

  const contextValue = useMemo(
    () => ({ deaLicenses, handleDEALicenseData, handleDeleteDEALicenseData }),
    [deaLicenses]
  );

  return (
    <DeaLicenseContext.Provider value={contextValue}>
      {children}
    </DeaLicenseContext.Provider>
  );
}
