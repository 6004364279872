/* eslint-disable */

import { APIService } from 'services/api-service';

import { UserServiceNS } from './types';
import { NOT_ALLOWED_SUFFIXES } from 'utils/common';

export class UserService extends APIService {
  static USER_PROFILE = `${this.API_PREFIX_URL}/profile/`;
  static getUserProfile = async () =>
    this.get<UserServiceNS.GetUserProfileResponse>(UserService.USER_PROFILE, {
      withAuthToken: true,
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });

  static UPDATE_USER_PASSWORD = `${this.API_PREFIX_URL}/profile/password`;
  static updateUserPassword = ({
    oldPassword,
    password,
  }: UserServiceNS.UpdateUserPasswordBody) =>
    this.put(
      UserService.UPDATE_USER_PASSWORD,
      {
        oldPassword,
        newPassword: password,
      },
      {
        withAuthToken: true,
      }
    );

  static PROFILE_DETAILS = `${this.API_PREFIX_URL}/profile/details`;
  static getProfileDetails = () =>
    this.get<UserServiceNS.GetUserDetailsResponse>(
      UserService.PROFILE_DETAILS,

      {
        withAuthToken: true,
      }
    );

  static UPDATE_PHONE = `${this.API_PREFIX_URL}/profile/phone`;
  static updatePhoneNumber = ({ phone }: UserServiceNS.UpdatePhoneBody) =>
    this.put(
      UserService.UPDATE_PHONE,
      {
        phone,
      },
      {
        withAuthToken: true,
      }
    );

  static UPDATE_EMAIL = `${this.API_PREFIX_URL}/profile/email`;
  static updateEmail = ({ email }: UserServiceNS.UpdateEmailBody) =>
    this.put(
      UserService.UPDATE_EMAIL,
      {
        email,
      },
      {
        withAuthToken: true,
      }
    );

  static VERIFY_EMAIL = `${this.API_PREFIX_URL}/profile/verify-email`;
  static verifyEmail = ({ token }: UserServiceNS.VerifyEmailBody) => {
    return this.put(`${UserService.VERIFY_EMAIL}?token=${token}`, undefined, {
      withAuthToken: true,
    });
  };

  static ADD_PROFILE_PICTURE = `${this.API_PREFIX_URL}/onboard/profile-picture`;
  static addProfilePicture = ({
    picture,
    token,
  }: UserServiceNS.PostProfilePictureBody) => {
    const formData = new FormData();
    formData.append('picture', picture);
    return this.put<{ profilePicture: string }>(
      `${UserService.ADD_PROFILE_PICTURE}?token=${token}`,
      formData,
      {
        withAuthToken: true,
        formData: true,
      }
    );
  };

  static ONBOARD_PROVIDER_URL = `${this.API_PREFIX_URL}/onboard/provider`;
  static onBoardProvider = async ({
    token,
    providerDetails,
  }: UserServiceNS.OnboardProviderBody) =>
    this.post(
      `${UserService.ONBOARD_PROVIDER_URL}?token=${token}`,
      { ...providerDetails },
      {
        withAuthToken: true,
      }
    );

  static GET_PROVIDER_PRACTICE_DETAILS = `${this.API_PREFIX_URL}/onboard/practice`;
  static getProviderPracticeDetails = async ({
    token,
  }: UserServiceNS.GetProviderPracticeDetailsBody) =>
    this.get<UserServiceNS.GetProviderPracticeDetailsResponse>(
      `${UserService.GET_PROVIDER_PRACTICE_DETAILS}?token=${token}`,
      {
        withAuthToken: false,
      }
    );

  static GET_PROVIDER_SPECIALTIES = `${this.API_PREFIX_URL}/provider/specialties`;
  static getProviderSpecialties = async () =>
    this.get<UserServiceNS.GetProviderSpecialtiesResponse[]>(
      `${UserService.GET_PROVIDER_SPECIALTIES}`,
      {
        withAuthToken: false,
      }
    );

  static ONBOARD_ADMIN_URL = `${this.API_PREFIX_URL}/onboard/admin`;
  static onBoardAdmin = async ({
    token,
    adminDetails,
  }: UserServiceNS.OnboardAdminBody) =>
    this.post(
      `${UserService.ONBOARD_ADMIN_URL}?token=${token}`,
      { ...adminDetails },
      {
        withAuthToken: true,
      }
    );

  static ONBOARD_PHARMACY_USER_URL = `${this.API_PREFIX_URL}/onboard/pharmacy`;
  static onboardPharmacyUser = async ({
    token,
    pharmacyUserDetails,
  }: UserServiceNS.OnboardPharmacyUserBody) =>
    this.post(
      `${UserService.ONBOARD_PHARMACY_USER_URL}?token=${token}`,
      { ...pharmacyUserDetails },
      {
        withAuthToken: true,
      }
    );

  static ONBOARD_PRACTICE_ADMIN_URL = `${this.API_PREFIX_URL}/onboard/practice-admin`;
  static onBoardPracticeAdmin = async ({
    token,
    practiceAdminDetails,
  }: UserServiceNS.OnboardPracticeAdminBody) =>
    this.post(
      `${UserService.ONBOARD_PRACTICE_ADMIN_URL}?token=${token}`,
      { ...practiceAdminDetails },
      {
        withAuthToken: true,
      }
    );

  static ONBOARD_PATIENT_URL = `${this.API_PREFIX_URL}/onboard/patient`;
  static onboardPatient = ({
    firstName,
    lastName,
    details,
    phone,
    password,
    driversLicenseDetails,
    ssn,
    token,
    hasDriverLicense,
  }: UserServiceNS.OnboardPatientBody) => {
    const patientsIdentity = hasDriverLicense
      ? { driversLicenseDetails }
      : { ssn };

    return this.post<UserServiceNS.OnboardResponse>(
      UserService.ONBOARD_PATIENT_URL,
      {
        password,
        firstName,
        lastName,
        details: { ...details, user_consent: true },
        phone,
        ...patientsIdentity,
        token,
      },
      {
        withAuthToken: true,

        params: {
          token,
        },
      }
    );
  };

  static UPLOAD_PATIENT_PROFILE_IMAGE_URL = `${this.API_PREFIX_URL}/onboard/profile-picture`;
  static uploadPatientProfileImage = ({
    picture,
    token,
  }: UserServiceNS.UploadOnboardingPatientProfilePicture) => {
    const formData = new FormData();
    formData.append('picture', picture);

    return this.put(UserService.UPLOAD_PATIENT_PROFILE_IMAGE_URL, formData, {
      withAuthToken: false,
      formData: true,
      params: {
        token,
      },
    });
  };

  static ONBOARD_PATIENT_PRACTICE = `${this.API_PREFIX_URL}/onboard/practice`;
  static getOnboardPractice = ({
    token,
  }: UserServiceNS.GetOnboardPracticeBody) =>
    this.get<UserServiceNS.GetOnboardPracticeResponse>(
      UserService.ONBOARD_PATIENT_PRACTICE,
      {
        withAuthToken: false,
        params: {
          token,
        },
      }
    );

  static GET_STATES = `${this.API_PREFIX_URL}/profile/states`;
  static getStates = async () =>
    this.get<UserServiceNS.GetStatesResponse[]>(UserService.GET_STATES, {
      withAuthToken: true,
    });

  static UPLOAD_PATIENT_LICENSE_IMAGE_URL = `${this.API_PREFIX_URL}/onboard/patient/driving-license`;
  static uploadPatientLicenseImage = ({
    picture,
    token,
  }: UserServiceNS.UploadOnboardingPatientLicensePicture) => {
    const formData = new FormData();
    formData.append('picture', picture);

    return this.put(UserService.UPLOAD_PATIENT_LICENSE_IMAGE_URL, formData, {
      withAuthToken: false,
      formData: true,
      params: {
        token,
      },
    });
  };

  static UPLOAD_PATIENT_IDENTITY_PHOTO_URL = `${this.API_PREFIX_URL}/onboard/patient/photo-identity`;
  static uploadIdentityImage = ({
    picture,
    token,
  }: UserServiceNS.UploadOnboardingPatientLicensePicture) => {
    const formData = new FormData();
    formData.append('picture', picture);

    return this.put(UserService.UPLOAD_PATIENT_IDENTITY_PHOTO_URL, formData, {
      withAuthToken: false,
      formData: true,
      params: {
        token,
      },
    });
  };

  static PROVIDER_SUFFIX_URL = `${this.API_PREFIX_URL}/provider/suffixes`;
  static getProviderSuffixes = async () => {
    const _response = await this.get<UserServiceNS.GetProviderSuffixes>(
      UserService.PROVIDER_SUFFIX_URL,

      {
        withAuthToken: true,
      }
    );
    const filteredSuffixes = _response.data.suffixes.filter(
      (suffix) => !NOT_ALLOWED_SUFFIXES.includes(suffix)
    );
    _response.data.suffixes = filteredSuffixes;

    return _response;
  };

  static ONBOARD_STAFF_URL = `${this.API_PREFIX_URL}/onboard/staff`;
  static onBoardStaff = async ({
    token,
    staffDetails,
  }: UserServiceNS.OnboardStaffBody) =>
    this.post(
      `${UserService.ONBOARD_STAFF_URL}?token=${token}`,
      { ...staffDetails },
      {
        withAuthToken: true,
      }
    );
}
