import React, { Fragment } from 'react';

import './skeleton.css';

import classNames from 'classnames';
import { FaRegSadTear } from 'react-icons/fa';

import { ThemeName } from 'theme/types';

import { Typography } from '../Typography/Typography';

type styleType = React.CSSProperties & {
  className: string;
  key?: string;
  children?: layoutType;
};

export type layoutType = Array<styleType>;
type SkeletonErrorResultType =
  | {
      isError?: boolean;
      throwError?: true;
      errorMessage?: string;
      renderOnError?: never;
    }
  | {
      isError?: boolean;
      throwError?: never;
      renderOnError?: () => JSX.Element;
      errorMessage?: never;
    };

type SkeletonProps = {
  layout: layoutType;
  containerClassName?: string;
  isLoading: boolean;
  theme?: ThemeName;
  children?: () => JSX.Element[] | JSX.Element;
} & SkeletonErrorResultType;

export function SkeletonContent({
  containerClassName,
  layout,
  isLoading = true,
  throwError,
  errorMessage,
  renderOnError,
  children,
  theme = ThemeName.Dark,
  isError,
}: SkeletonProps) {
  if (isError) {
    if (throwError) {
      throw new Error(
        errorMessage || 'API call failed due to an unexpected error.'
      );
    }

    return renderOnError ? (
      renderOnError()
    ) : (
      <div className="grid h-full min-h-[5rem] w-full place-items-center rounded-md bg-background-main">
        <Typography className="text-error-light" variant="subtitle2">
          <FaRegSadTear className="mr-2 text-2xl" /> Whoops..could not get data.
          API call has failed!
        </Typography>
      </div>
    );
  }

  const getBoneContainer = (
    layoutStyle: styleType,
    childrenBones: JSX.Element[],
    key: number | string,
    hasChildren: boolean,
    className: string,
    styles: React.CSSProperties
  ) => (
    <div
      className={`${
        !hasChildren &&
        (theme === ThemeName.Dark ? 'skeleton' : 'skeleton-light')
      } animate-pulse rounded ${className}`}
      key={layoutStyle.key || key}
      style={styles}>
      {childrenBones}
    </div>
  );

  const getBones = (
    bonesLayout: layoutType,
    prefix: string | number = ''
  ): JSX.Element[] => {
    if (bonesLayout && bonesLayout.length > 0) {
      return bonesLayout.map((layoutStyle: styleType, index: number) => {
        const {
          className,
          children: childrenLayout,
          key,
          ...styles
        } = layoutStyle;

        const containerPrefix = key || `${prefix}_${index}`;

        if (childrenLayout && childrenLayout.length > 0) {
          const childBones = getBones(childrenLayout, containerPrefix);

          return getBoneContainer(
            layoutStyle,
            childBones,
            containerPrefix,
            true,
            className,
            styles
          );
        }

        return getBoneContainer(
          layoutStyle,
          [],
          containerPrefix,
          false,
          className,
          styles
        );
      });
    }

    return [];
  };

  return isLoading ? (
    <div className={classNames(containerClassName)}>
      {getBones(layout, 'boneContainer')}
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children?.()}</>
  );
}
