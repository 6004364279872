export const agreementTypes = {
  nonConcierge: (
    <div>
      The BRITE™ Membership Program provides Members access to bioidentical
      hormones provided in an organic, solvent-free base (BRITE) on an automatic
      refill program as prescribed by your health care practitioner. BRITE Live
      can also fulfill other pharmaceutical prescriptions; please check with us
      for current availability. The Brite Live™ app is designed to help you and
      your healthcare provider get your dosages precisely matched to your
      individual body’s needs and to remind you when it is time to take your
      hormones as well as the prescribed amount. The app also helps you make
      approved adjustments to your dosages, communicate with your healthcare
      provider, and request refills when you are running low. Ultimately, all
      medical decisions are made and managed by you and your healthcare
      provider. You should rely upon your healthcare provider’s advice. BRITE
      does not become responsible for health care choices made by you or your
      prescribing practitioner as a result of suggestions made by the App nor
      are we engaging in a physician/patient relationship with you by offering
      this service.
      <br /> <br /> The current pricing for BRITE hormones for women and men is
      listed below. There is no additional fee for the use of the BRITE Live
      App. At the discretion of your health care provider, oral progesterone
      and/or oral DHEA may be substituted for topical (with no changes to
      pricing):
      <br /> <br /> $124 per month OR $1,188 annually for all four ovarian
      hormones (Estrogen(s), Testosterone, Progesterone, and DHEA).
      <br /> <br /> $111 per month OR $1,068 per year for any three ovarian
      hormones
      <br /> <br /> $74 per month OR $708 per year for any two ovarian hormones{' '}
      <br /> <br /> $46 per month OR $444 per year for any one ovarian hormone
      <br /> <br /> The current pricing for BRITE Testosterone for men is:{' '}
      <br /> <br /> $71 per month OR $716 per year
      <br /> <br /> Pricing; Insurance Notice: As the developer of our unique,
      patented topical base and relationship with Curexa and other fine
      pharmacies, we are able to provide these quality products at unusually low
      prices. The subscription fees (listed above), shipping, and any additional
      prescription costs are payable by member on a cash basis. Prescriptions
      are not reimbursed by insurance and claims may not be submitted to
      insurance companies or Medicare. You may opt to cancel your subscription
      in this Program at any time. Termination requests received before shipment
      of monthly subscription orders will be effective when received; if
      shipment has already occurred termination will be effective the following
      month. For annual members, refunds will be provided less the fees at the
      monthly rate for subscription scripts filled. <br /> <br /> All other
      prescriptions are based on the costs of that particular
      product/pharmaceutical and will be charged on a per-prescription basis.
      These additional pharmaceuticals are optional and you and/or your health
      care provider may opt to have any or all of these non-BRITE prescriptions
      fulfilled by a pharmacy of your and/or your health care provider’s choice.
      BRITE does not accept insurance and you may wish to determine if these
      prescriptions are covered at your local pharmacy and what out-of-pocket
      costs you would incur.
      <br /> <br /> Child-Resistant Packaging: Some prescriptions will be
      shipped to you in Non-Child-Resistant containers.
      <br /> <br /> Credit Card Authorization; Collection Expenses. BRITE Live
      is authorized by this Agreement to charge your credit card for BRITE Live
      monthly Membership Fees and for other ordered prescriptions without
      additional authorization. In the event of any dispute, you agree to
      contact us to resolve it and allow us two weeks to resolve it before
      initiating a chargeback with your credit card company. If you cancel the
      credit card provided to BRITE you will provide us authorization to charge
      a replacement credit card prior to the next billing cycle. You will be
      responsible for any fees or costs incurred by BRITE in connection with
      collection, including attorney’s fees and costs and fees associated with
      recouping payment on fees due or on chargebacks. You will update BRITE in
      advance in the event of a change in your credit card information,
      including changes to the name on your card, your billing address and phone
      number. Any payments more than five days late will incur customary
      interest charges.
      <br /> <br /> Our therapeutic objective is to replenish and balance your
      hormones to the levels necessary to alleviate hormonal insufficiencies and
      symptoms and to restore the protections that ovarian, testicular, or other
      hormones can provide over both the short and the long term. Optimal care
      is highly personalized; determining optimal therapeutic dosages requires
      that a patient be followed and have regular access to their prescribing
      provider, particularly during the initial months as well as during periods
      of change as the optimal dosages are determined and tested. Our
      prescriptions are based on the costs of that particular
      product/pharmaceutical and will be charged on a per-prescription basis.
      Any additional pharmaceuticals are optional and up to you and/or your
      healthcare provider.
      <br /> <br /> I have read and agree to the foregoing terms and conditions.
    </div>
  ),

  conciergeSubscriptionAndRxs: (
    <div>
      The BRITE™ Membership Program provides Members access to bioidentical
      hormones provided in an organic, solvent-free base (BRITE) on an automatic
      refill program as prescribed by your health care practitioner. BRITE Live
      can also fulfill other pharmaceutical prescriptions; please check with us
      for current availability.
      <br />
      <br /> The Brite Live™ app is designed to help you and your healthcare
      provider get your dosages precisely matched to your individual body’s
      needs and to remind you when it is time to take your hormones as well as
      the prescribed amount. The app also helps you make approved adjustments to
      your dosages, communicate with your healthcare provider, and request
      refills when you are running low. Ultimately, all medical decisions are
      made and managed by you and your healthcare provider. You should rely upon
      your healthcare provider’s advice. BRITE does not become responsible for
      health care choices made by you or your prescribing practitioner as a
      result of suggestions made by the App nor are we engaging in a
      physician/patient relationship with you by offering this service.
      <br />
      <br /> Pricing; Insurance Notice: As the developer of our unique, patented
      topical base and relationship with Curexa and other fine pharmacies, we
      are able to provide these quality products at unusually low prices.
      Shipping costs are payable by member on a cash basis. Prescriptions are
      not reimbursed by insurance and claims may not be submitted to insurance
      companies or Medicare. You may opt to cancel your subscription in this
      Program at any time. Termination requests received before shipment of
      monthly subscription orders will be effective when received; if shipment
      has already occurred termination will be effective the following month.
      For annual members, refunds will be provided less the fees at the monthly
      rate for subscription scripts filled.
      <br />
      <br /> All other prescriptions are based on the costs of that particular
      product/pharmaceutical and will be charged on a per-prescription basis.
      These additional pharmaceuticals are optional and you and/or your health
      care provider may opt to have any or all of these non-BRITE prescriptions
      fulfilled by a pharmacy of your and/or your health care provider’s choice.
      BRITE does not accept insurance and you may wish to determine if these
      prescriptions are covered at your local pharmacy and what out-of-pocket
      costs you would incur.
      <br />
      <br /> Child-Resistant Packaging: Some prescriptions will be shipped to
      you in Non-Child-Resistant containers.
      <br />
      <br /> Credit Card Authorization; Collection Expenses. BRITE Live is
      authorized by this Agreement to charge your credit card for BRITE Live
      monthly Membership Fees and for other ordered prescriptions without
      additional authorization. In the event of any dispute, you agree to
      contact us to resolve it and allow us two weeks to resolve it before
      initiating a chargeback with your credit card company. If you cancel the
      credit card provided to BRITE you will provide us authorization to charge
      a replacement credit card prior to the next billing cycle. You will be
      responsible for any fees or costs incurred by BRITE in connection with
      collection, including attorney’s fees and costs and fees associated with
      recouping payment on fees due or on chargebacks. You will update BRITE in
      advance in the event of a change in your credit card information,
      including changes to the name on your card, your billing address and phone
      number. Any payments more than five days late will incur customary
      interest charges.
      <br />
      <br /> Our therapeutic objective is to replenish and balance your hormones
      to the levels necessary to alleviate hormonal insufficiencies and symptoms
      and to restore the protections that ovarian, testicular, or other hormones
      can provide over both the short and the long term. Optimal care is highly
      personalized; determining optimal therapeutic dosages requires that a
      patient be followed and have regular access to their prescribing provider,
      particularly during the initial months as well as during periods of change
      as the optimal dosages are determined and tested. Our prescriptions are
      based on the costs of that particular product/pharmaceutical and will be
      charged on a per-prescription basis. Any additional pharmaceuticals are
      optional and up to you and/or your healthcare provider.
      <br />
      <br /> I have read and agree to the foregoing terms and conditions.
    </div>
  ),

  conciergeSubscriptionAndShipping: (
    <div>
      The BRITE™ Membership Program provides Members access to bioidentical
      hormones provided in an organic, solvent-free base (BRITE) on an automatic
      refill program as prescribed by your health care practitioner. BRITE Live
      can also fulfill other pharmaceutical prescriptions; please check with us
      for current availability.
      <br />
      <br /> The Brite Live™ app is designed to help you and your healthcare
      provider get your dosages precisely matched to your individual body’s
      needs and to remind you when it is time to take your hormones as well as
      the prescribed amount. The app also helps you make approved adjustments to
      your dosages, communicate with your healthcare provider, and request
      refills when you are running low. Ultimately, all medical decisions are
      made and managed by you and your healthcare provider. You should rely upon
      your healthcare provider’s advice. BRITE does not become responsible for
      health care choices made by you or your prescribing practitioner as a
      result of suggestions made by the App nor are we engaging in a
      physician/patient relationship with you by offering this service.
      <br />
      <br /> Pricing; Insurance Notice: As the developer of our unique, patented
      topical base and relationship with Curexa and other fine pharmacies, we
      are able to provide these quality products at unusually low prices. Any
      non-hormone prescription costs are payable by member on a cash basis.
      Prescriptions are not reimbursed by insurance and claims may not be
      submitted to insurance companies or Medicare. You may opt to cancel your
      subscription in this Program at any time. Termination requests received
      before shipment of monthly subscription orders will be effective when
      received; if shipment has already occurred termination will be effective
      the following month. For annual members, refunds will be provided less the
      fees at the monthly rate for subscription scripts filled.
      <br />
      <br /> All other prescriptions are based on the costs of that particular
      product/pharmaceutical and will be charged on a per-prescription basis.
      These additional pharmaceuticals are optional and you and/or your health
      care provider may opt to have any or all of these non-BRITE prescriptions
      fulfilled by a pharmacy of your and/or your health care provider’s choice.
      BRITE does not accept insurance and you may wish to determine if these
      prescriptions are covered at your local pharmacy and what out-of-pocket
      costs you would incur.
      <br />
      <br /> Child-Resistant Packaging: Some prescriptions will be shipped to
      you in Non-Child-Resistant containers.
      <br />
      <br /> Credit Card Authorization; Collection Expenses. BRITE Live is
      authorized by this Agreement to charge your credit card for BRITE Live
      monthly Membership Fees and for other ordered prescriptions without
      additional authorization. In the event of any dispute, you agree to
      contact us to resolve it and allow us two weeks to resolve it before
      initiating a chargeback with your credit card company. If you cancel the
      credit card provided to BRITE you will provide us authorization to charge
      a replacement credit card prior to the next billing cycle. You will be
      responsible for any fees or costs incurred by BRITE in connection with
      collection, including attorney’s fees and costs and fees associated with
      recouping payment on fees due or on chargebacks. You will update BRITE in
      advance in the event of a change in your credit card information,
      including changes to the name on your card, your billing address and phone
      number. Any payments more than five days late will incur customary
      interest charges.
      <br />
      <br /> Our therapeutic objective is to replenish and balance your hormones
      to the levels necessary to alleviate hormonal insufficiencies and symptoms
      and to restore the protections that ovarian, testicular, or other hormones
      can provide over both the short and the long term. Optimal care is highly
      personalized; determining optimal therapeutic dosages requires that a
      patient be followed and have regular access to their prescribing provider,
      particularly during the initial months as well as during periods of change
      as the optimal dosages are determined and tested. Our prescriptions are
      based on the costs of that particular product/pharmaceutical and will be
      charged on a per-prescription basis. Any additional pharmaceuticals are
      optional and up to you and/or your healthcare provider.
      <br />
      <br /> I have read and agree to the foregoing terms and conditions.
    </div>
  ),
  conciergeSubscription: (
    <div>
      The BRITE™ Membership Program provides Members access to bioidentical
      hormones provided in an organic, solvent-free base (BRITE) on an automatic
      refill program as prescribed by your health care practitioner. BRITE Live
      can also fulfill other pharmaceutical prescriptions; please check with us
      for current availability.
      <br />
      <br /> The Brite Live™ app is designed to help you and your healthcare
      provider get your dosages precisely matched to your individual body’s
      needs and to remind you when it is time to take your hormones as well as
      the prescribed amount. The app also helps you make approved adjustments to
      your dosages, communicate with your healthcare provider, and request
      refills when you are running low. Ultimately, all medical decisions are
      made and managed by you and your healthcare provider. You should rely upon
      your healthcare provider’s advice. BRITE does not become responsible for
      health care choices made by you or your prescribing practitioner as a
      result of suggestions made by the App nor are we engaging in a
      physician/patient relationship with you by offering this service.
      <br />
      <br /> Pricing; Insurance Notice: As the developer of our unique, patented
      topical base and relationship with Curexa and other fine pharmacies, we
      are able to provide these quality products at unusually low prices.
      Shipping and any additional non-hormone prescription costs are payable by
      member on a cash basis. Prescriptions are not reimbursed by insurance and
      claims may not be submitted to insurance companies or Medicare. You may
      opt to cancel your subscription in this Program at any time. Termination
      requests received before shipment of monthly subscription orders will be
      effective when received; if shipment has already occurred termination will
      be effective the following month. For annual members, refunds will be
      provided less the fees at the monthly rate for subscription scripts
      filled.
      <br />
      <br /> All other prescriptions are based on the costs of that particular
      product/pharmaceutical and will be charged on a per-prescription basis.
      These additional pharmaceuticals are optional and you and/or your health
      care provider may opt to have any or all of these non-BRITE prescriptions
      fulfilled by a pharmacy of your and/or your health care provider’s choice.
      BRITE does not accept insurance and you may wish to determine if these
      prescriptions are covered at your local pharmacy and what out-of-pocket
      costs you would incur.
      <br />
      <br /> Child-Resistant Packaging: Some prescriptions will be shipped to
      you in Non-Child-Resistant containers.
      <br />
      <br /> Credit Card Authorization; Collection Expenses. BRITE Live is
      authorized by this Agreement to charge your credit card for BRITE Live
      monthly Membership Fees and for other ordered prescriptions without
      additional authorization. In the event of any dispute, you agree to
      contact us to resolve it and allow us two weeks to resolve it before
      initiating a chargeback with your credit card company. If you cancel the
      credit card provided to BRITE you will provide us authorization to charge
      a replacement credit card prior to the next billing cycle. You will be
      responsible for any fees or costs incurred by BRITE in connection with
      collection, including attorney’s fees and costs and fees associated with
      recouping payment on fees due or on chargebacks. You will update BRITE in
      advance in the event of a change in your credit card information,
      including changes to the name on your card, your billing address and phone
      number. Any payments more than five days late will incur customary
      interest charges.
      <br />
      <br /> Our therapeutic objective is to replenish and balance your hormones
      to the levels necessary to alleviate hormonal insufficiencies and symptoms
      and to restore the protections that ovarian, testicular, or other hormones
      can provide over both the short and the long term. Optimal care is highly
      personalized; determining optimal therapeutic dosages requires that a
      patient be followed and have regular access to their prescribing provider,
      particularly during the initial months as well as during periods of change
      as the optimal dosages are determined and tested. Our prescriptions are
      based on the costs of that particular product/pharmaceutical and will be
      charged on a per-prescription basis. Any additional pharmaceuticals are
      optional and up to you and/or your healthcare provider.
      <br />
      <br /> I have read and agree to the foregoing terms and conditions.
    </div>
  ),
  conciergeSubscriptionMedicationAndShip: (
    <div>
      The BRITE™ Membership Program provides Members access to bioidentical
      hormones provided in an organic, solvent-free base (BRITE) on an automatic
      refill program as prescribed by your health care practitioner. BRITE Live
      can also fulfill other pharmaceutical prescriptions; please check with us
      for current availability.
      <br />
      <br /> The Brite Live™ app is designed to help you and your healthcare
      provider get your dosages precisely matched to your individual body’s
      needs and to remind you when it is time to take your hormones as well as
      the prescribed amount. The app also helps you make approved adjustments to
      your dosages, communicate with your healthcare provider, and request
      refills when you are running low. Ultimately, all medical decisions are
      made and managed by you and your healthcare provider. You should rely upon
      your healthcare provider’s advice. BRITE does not become responsible for
      health care choices made by you or your prescribing practitioner as a
      result of suggestions made by the App nor are we engaging in a
      physician/patient relationship with you by offering this service.
      <br />
      <br /> Pricing; Insurance Notice: As the developer of our unique, patented
      topical base and relationship with Curexa and other fine pharmacies, we
      are able to provide these quality products. Prescriptions are not
      reimbursed by insurance and claims may not be submitted to insurance
      companies or Medicare. You may opt to cancel your subscription in this
      Program at any time. Termination requests received before shipment of
      monthly subscription orders will be effective when received; if shipment
      has already occurred termination will be effective the following month.
      For annual members, refunds will be provided less the fees at the monthly
      rate for subscription scripts filled.
      <br />
      <br /> All other prescriptions are based on the costs of that particular
      product/pharmaceutical and will be charged on a per-prescription basis.
      These additional pharmaceuticals are optional and you and/or your health
      care provider may opt to have any or all of these non-BRITE prescriptions
      fulfilled by a pharmacy of your and/or your health care provider’s choice.
      BRITE does not accept insurance and you may wish to determine if these
      prescriptions are covered at your local pharmacy and what out-of-pocket
      costs you would incur.
      <br />
      <br /> Child-Resistant Packaging: Some prescriptions will be shipped to
      you in Non-Child-Resistant containers.
      <br />
      <br /> Credit Card Authorization; Collection Expenses. BRITE Live is
      authorized by this Agreement to charge your credit card for BRITE Live
      monthly Membership Fees and for other ordered prescriptions without
      additional authorization. In the event of any dispute, you agree to
      contact us to resolve it and allow us two weeks to resolve it before
      initiating a chargeback with your credit card company. If you cancel the
      credit card provided to BRITE you will provide us authorization to charge
      a replacement credit card prior to the next billing cycle. You will be
      responsible for any fees or costs incurred by BRITE in connection with
      collection, including attorney’s fees and costs and fees associated with
      recouping payment on fees due or on chargebacks. You will update BRITE in
      advance in the event of a change in your credit card information,
      including changes to the name on your card, your billing address and phone
      number. Any payments more than five days late will incur customary
      interest charges.
      <br />
      <br /> Our therapeutic objective is to replenish and balance your hormones
      to the levels necessary to alleviate hormonal insufficiencies and symptoms
      and to restore the protections that ovarian, testicular, or other hormones
      can provide over both the short and the long term. Optimal care is highly
      personalized; determining optimal therapeutic dosages requires that a
      patient be followed and have regular access to their prescribing provider,
      particularly during the initial months as well as during periods of change
      as the optimal dosages are determined and tested. Our prescriptions are
      based on the costs of that particular product/pharmaceutical and will be
      charged on a per-prescription basis. Any additional pharmaceuticals are
      optional and up to you and/or your healthcare provider.
      <br />
      <br /> I have read and agree to the foregoing terms and conditions.
    </div>
  ),
};

export const BHRTAgreementText = (
  <div>
    Hormone replacement therapy (HRT), its bioidentical form (BHRT), and as
    compounded bioidentical hormones replenishment therapy (cBHRT) are subject
    to potential risks and some controversy. BRITE members should review the
    contraindications, potential side effects, understand the nature of these
    controversies and have signed an informed consent form with their ordering
    physician/nurse practitioner before ordering BRITE cBHRT products.
    <br />
    <br />
    While pharmaceutical manufactured BHRT products are approved by the Food and
    Drug Administration (FDA). cBHRT has not undergone an FDA approval process
    as compounded drugs are not subject to the drug approval process. The FDA
    does not assure the safety and effectiveness of any compounded products. The
    ingredients compounding pharmacists use to dispense compounded hormone
    prescriptions are produced in FDA approved manufacturing facilities and
    compounded by state licensed pharmacies, and are carefully tested for
    purity, sterility, durability, potency and other factors.
    <br />
    <br />
    Members are provided the Compounded Drug and Base (“Medication”) on the
    following terms and conditions:
    <br />
    <br />
    Limitation of Warranties. This compounded drug is provided as is and without
    any representation or warranty, express or implied. BRITE, its employees,
    agents, and representatives, specifically disclaim the Warranty of
    Merchantability: We do not warrant that the compounded drug will meet your
    requirements or that its use will be uninterrupted or error-free; the
    Warranty of Fitness for a Particular Purpose: We do not warrant that the
    compounded drug will be fit for your intended purpose. Member acknowledges
    and agrees that you have exercised your independent judgment in acquiring
    the compounded drug and have not relied on any representation we have made
    which has not been stated expressly in this disclaimer or upon any
    descriptions or illustrations or specifications contained in any document
    including catalogues or publicity material produced by us. BRITE&lsquo;s
    liability is limited to replacing the Medication or refunding the purchase
    price. This disclaimer is governed by the laws of the United States and the
    Member&lsquo;s state, and any disputes will be resolved through arbitration.
    <br />
    <br />
    Assumption of Risk. Member acknowledges that compounded medications involve
    inherent risks, including but not limited to allergic reactions, side
    effects or adverse drug interactions and that, while controversial, cBHRT
    may raises the risk of certain cancers, heart attack or stroke. Member
    assumes all risks associated with the use of the BRITE compounded products
    including but not limited to efficacy, side effects, adverse reactions and
    health consequences, whether expected or unexpected, and whether or not
    listed in the Medication&lsquo;s accompanying literature.
    <br />
    <br />
    Release and Waiver. Member releases and discharges BRITE, its employees,
    agents, and representatives, from any and all claims, liabilities, damages,
    actions, causes of action, costs, loss, or expense, including attorney fees,
    present or future, whether known or unknown, anticipated or unanticipated,
    resulting from or arising out of the compounding, dispensing or
    Member&lsquo;s use of the Medication. This release and waiver applies to any
    and all claims, including but not limited to product liability claims,
    breach of warranty, and negligence.
    <br />
    <br />
    No Guarantees. BRITE makes no guarantees or warranties concerning the Drug.
    BRITE though its affiliated pharmacies has compounded the Medication in
    accordance with applicable state and federal laws and compounding standards.
    However, there is no guarantee that the Medication will achieve any
    particular result for Member or that side effects will be avoided.
    <br />
    <br />
    Affiliated Pharmacies. BRITE selects qualified compounding pharmacies to
    fulfill Member&lsquo;s orders. The terms and conditions of this disclaimer,
    including but not limited to, limitation of warranties, assumption of risk,
    release and waiver, and no guarantees shall apply to the pharmacy through
    which Member&lsquo;s order for the Medication is fulfilled.
    <br />
    <br />
    Not Practicing Medicine. BRITE is not giving medical advice or engaged in a
    doctor-patient relationship with Member. The fulfillment of orders for the
    Medication or the provision of recommended protocols and collection of data
    to assist Member&lsquo;s practitioner in titrating Medication is solely for
    the purpose of supporting Member&lsquo;s physician or nurse practitioner in
    determining, in their independent medical judgment, proper prescribing of
    the medication.
    <br />
    <br />
    Severability. If any provision of this Disclaimer is deemed invalid or
    unenforceable, the remaining provisions shall still be enforced.
    <br />
    <br />
    Member acknowledges that Member has read, understands, and accepts the terms
    of this Disclaimer and understands that it limits his or her legal recourse.
    This Disclaimer must be signed before BRITE will dispense Medication to
    Member. Member further confirms that he or she has been adequately informed
    about the nature and purpose of the compounded medication, its potential
    benefits and risks, and alternative treatments by their ordering physician
    or nurse practitioner, had the opportunity to ask questions to their
    satisfaction and has executed an informed consent for these Medications.
  </div>
);
