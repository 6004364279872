import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';

type SupervisingProviderConfirmationModalProps = {
  state: string;
  showConfirmationMoal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function SupervisingProviderConfirmationModal({
  state,
  showConfirmationMoal,
  onCancel,
  onConfirm,
}: SupervisingProviderConfirmationModalProps) {
  return (
    <Modal
      className="w-4/6"
      open={showConfirmationMoal}
      closeModal={() => {
        //
      }}>
      <div>
        <Typography variant="subtitle1" className="my-1">
          <div>
            Are you sure you don’t need a supervising provider for the state
            of&nbsp;
            {/* eslint-disable-next-line */}
            <dfn className="not-italic text-primary-main">{state}?</dfn>
          </div>
        </Typography>
        <Typography variant="subtitle1">
          If the state of {state} requires supervising provider and you have not
          provided it then your account won’t be configured properly to issue
          the prescriptions in the state of {state}. Hence, it is recommended to
          double check the details you have provided
        </Typography>

        <div className="mt-4 flex justify-end">
          <Button color="error" variant="contained" onClick={onCancel}>
            No, Double Check Details
          </Button>{' '}
          <span className="mx-2" />
          <Button onClick={onConfirm}>Proceed</Button>
        </div>
      </div>
    </Modal>
  );
}
