import { Modal } from 'components/common/Modal/Modal';
import { Typography } from 'components/common/Typography/Typography';

type ConfirmationModal = {
  descriptionText: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  openModal: boolean;
  handleConfirmation: (response: 'proceed' | 'cancel') => void;
};

export function ConfirmationModal({
  descriptionText,
  openModal,
  primaryButtonText,
  secondaryButtonText,
  handleConfirmation,
}: ConfirmationModal) {
  return (
    <Modal
      open={openModal}
      closeModal={() => {
        //
      }}>
      <div>
        <Typography color="gray" variant="h5">
          {descriptionText}
        </Typography>
      </div>

      <div
        role="button"
        className="mt-6  w-max rounded-lg border border-primary-main p-3"
        tabIndex={0}
        onClick={() => handleConfirmation('cancel')}
        onKeyDown={() => {
          //
        }}>
        <Typography variant="subtitle3" className="underline">
          {' '}
          {secondaryButtonText}
        </Typography>
      </div>

      <div
        role="button"
        tabIndex={0}
        className="text-white-main my-4 w-max rounded-lg  border border-primary-main bg-primary-main p-3"
        onClick={() => handleConfirmation('proceed')}
        onKeyDown={() => {
          //
        }}>
        <Typography variant="subtitle3" color="white" className="underline">
          {' '}
          {primaryButtonText}
        </Typography>
      </div>
    </Modal>
  );
}
