export const providerTitlesNotRequiringSupervisorDoctor = [
  'DDS',
  'DO',
  'MD',
  'OD',
  'FACC',
  'FACP',
  'RD',
  'VI',
  'DMD',
  'DVM',
];
