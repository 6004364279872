import React from 'react';

import classNames from 'classnames';

import { Typography } from '../Typography/Typography';

type TypoWithLinkProps = {
  text?: string;
  linkText?: string;
  onClick: () => void;
  className?: string;
};

export function TypoWithLink({
  text,
  linkText,
  onClick,
  className,
}: TypoWithLinkProps) {
  return (
    <Typography variant="subtitle2" className={classNames('flex ', className)}>
      <div>
        {text}
        {/* eslint-disable-next-line */}
        <dfn
          onClick={onClick}
          className={classNames(
            ' cursor-pointer not-italic text-[#83BCFF] underline hover:text-[#6996cc] active:text-[#6996cc]',
            {
              'ml-1': !!text,
            }
          )}>
          {linkText}
        </dfn>
      </div>

      <div className=" " />
    </Typography>
  );
}
