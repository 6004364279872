import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';

import { AppRouter } from 'router/AppRouter';

import { ThemeProvider } from './theme/ThemeProvider';

export function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AppRouter />

        <Toaster position="bottom-right" />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
