/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
  useState,
} from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { OnboardingProviderStepsType } from 'types/onboard';

type FormErrors = { [key: string]: string };
interface FormContextProps {
  formState: OnboardingProviderStepsType;
  formErrors: FormErrors[] | undefined;
  handleFormErrors: (obj: FormErrors) => void;
  updateFormState: (
    key: keyof OnboardingProviderStepsType,
    value: any | null
  ) => void;
}

const FormContext = createContext<FormContextProps | undefined>(undefined);

interface FormProviderProps {
  children: ReactNode;
}

export function OnboardFormProvider({ children }: FormProviderProps) {
  const [formErrors, setFormErrors] = useState<FormErrors[] | undefined>();
  const [formState, setFormState] =
    useLocalStorage<OnboardingProviderStepsType>('providerOnboardSteps', {
      basicInformation: null,
      password: null,
      picture: null,
      contactDetails: null,
      npiDeaLicense: null,
      specialities: null,
    });

  const handleFormErrors = (errObject: FormErrors) => {
    setFormErrors((prevError: FormErrors[] | undefined) => {
      const tempData = [...(prevError || [])];

      tempData.push(errObject);

      return tempData;
    });
  };

  const updateFormState = useCallback(
    (key: keyof OnboardingProviderStepsType, value: any | null) => {
      setFormState((prevState: OnboardingProviderStepsType) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [setFormState]
  );

  const value = useMemo(
    () => ({
      formState,
      updateFormState,
      formErrors,
      handleFormErrors,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState, updateFormState, formErrors]
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

export const useOnboardProviderFormContext = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useFormContext must be used within a FormProvider');
  }

  return context;
};
