import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Control, Controller } from 'react-hook-form';

import { ControllerOnChangeType } from 'types/inputTypes';

import { CheckBox } from '../CheckBox/CheckBox';
import { SearchInput } from '../SearchInput/SearchInput';
import { Typography } from '../Typography/Typography';

interface Option {
  // eslint-disable-next-line
  title: string;
  // eslint-disable-next-line
  value: string | number;
}

type ControlProps =
  | {
      // eslint-disable-next-line
      control?: Control<any, any>;
      name: string;
    }
  | {
      control?: false | undefined;
      name?: never;
    };

type MultiSelectDropDownProps = {
  options: Option[] | undefined;
  label: string;
  defaultValue?: Option[];
  searchable?: boolean;
  onChange?: (value: Option[]) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  helperText?: string;
  disableHelperText?: boolean;
  error?: boolean;
  required?: boolean;
} & ControlProps;
export function MultiSelectDropDown({
  label,
  required,
  options,
  defaultValue,
  searchable,
  onChange,
  fullWidth,
  disabled,
  className,
  control,
  name,
  helperText,
  disableHelperText,
  error,
}: MultiSelectDropDownProps) {
  const [searchText, setSearchText] = useState<string>('');
  const [value, setValue] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const checkboxRefs = useRef<HTMLInputElement[]>([]);

  const closeMenu = useCallback((event: MouseEvent) => {
    if ((event?.target as SVGElement).id === 'menu') {
      return;
    }
    if (
      (event?.target as SVGElement).id === 'DropDownbutton' ||
      (event?.target as SVGElement).id === 'searchInput'
    ) {
      return;
    }
    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', (e) => closeMenu(e));

    return () => document.body.removeEventListener('click', closeMenu);
  }, [closeMenu]);

  useEffect(() => {
    if (defaultValue) {
      setValue(() => [...defaultValue.map((v) => v.title)]);
    }
  }, [defaultValue]);
  // eslint-disable-next-line
  console.log(helperText, error);
  const getError = useCallback(() => {
    if (control) {
      if (control._formState.errors) {
        if (name.includes('.')) {
          const splittedArray = name.split('.');

          return splittedArray.reduce(
            // eslint-disable-next-line
            (acc: any, i) => acc?.[i],
            control._formState.errors
          );
        }

        return control._formState.errors?.[name];
      }
    }

    return undefined;
  }, [control, name]);

  const fieldError = getError();

  const dropDownValue = useMemo(
    () =>
      value[0]
        ? // eslint-disable-next-line
          value?.reduce((acc, option) => `${acc}, ${option}`)
        : undefined,
    [value]
  );

  const getDropdown = useCallback(
    (
      controllerOnChange?: ControllerOnChangeType,
      controllerValue: string[] = []
    ) => (
      <div id="DropDownbutton">
        {/*  eslint-disable-next-line */}
        <div
          id="DropDownbutton"
          onClick={() => setOpen(!open)}
          className={classNames(
            'flex w-full cursor-pointer  items-center justify-between rounded border border-transparent bg-background-light px-2 py-3 text-base text-background-contrastText ',
            { 'text-zinc-400': !value || !defaultValue },
            open ? 'rounded-b-none' : 'rounded',
            disabled
              ? 'pointer-events-none cursor-no-drop opacity-80'
              : 'cursor-pointer',
            {
              'border !border-error-main': !!fieldError,
            },
            {
              'border !border-primary-main': open,
            }
          )}>
          <span className="max-w-[90%] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {dropDownValue ? dropDownValue.toString() : label}
          </span>
          <ChevronDownIcon
            id="DropDownbutton"
            height={20}
            className={`${open && 'rotate-180'}`}
          />
        </div>
        <div
          className={classNames(
            `absolute mt-1 max-h-48  overflow-y-auto rounded-b bg-background-light ${
              open ? 'block' : 'hidden'
            } `,
            fullWidth ? 'w-full' : 'w-60'
          )}>
          {searchable && (
            <SearchInput
              fullWidth
              id="searchInput"
              onSearch={(v) => setSearchText(v)}
            />
          )}
          <ul
            className={classNames('p-2 pt-1 ', {
              'border-t border-t-zinc-400': searchable,
            })}>
            {options?.map(({ title, value: v }: Option, index) => (
              // eslint-disable-next-line
              <li
                onClick={() => {
                  checkboxRefs.current[index].click();
                }}
                id="menu"
                key={title}
                className={`flex cursor-pointer   items-center p-2 text-base text-background-contrastText hover:rounded-sm  hover:bg-background-main
           
            ${
              title.toLowerCase().startsWith(searchText) ||
              title.startsWith(searchText)
                ? 'block'
                : 'hidden'
            }`}>
                <CheckBox
                  setRef={(ref) => {
                    checkboxRefs.current[index] = ref as HTMLInputElement;
                  }}
                  className="!pointer-events-none"
                  id="menu"
                  onChange={
                    control
                      ? (checked: boolean) => {
                          if (!checked) {
                            value.splice(value.indexOf(title), 1);
                            setValue([...value]);
                            if (controllerOnChange) {
                              controllerValue.splice(
                                value.indexOf(v.toString()),
                                1
                              );
                              controllerOnChange({
                                target: {
                                  value: [...controllerValue],
                                  name: name ?? '',
                                },
                              });
                            }
                          } else {
                            setValue([...value, title]);
                            if (controllerOnChange) {
                              controllerOnChange({
                                target: {
                                  value: [...controllerValue, v],
                                  name: name ?? '',
                                },
                              });
                            }
                          }
                        }
                      : (checked: boolean) => {
                          if (!checked) {
                            value.splice(value.indexOf(title), 1);
                            setValue([...value]);
                          } else if (onChange) {
                            setValue([...value, title]);
                            onChange([{ title, value: v }]);
                          }
                        }
                  }
                  label=""
                  checked={value
                    ?.toString()
                    .toLowerCase()
                    .includes(title.toLowerCase())}
                />
                {title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    ),
    [
      value,
      searchable,
      searchText,
      options,
      open,
      onChange,
      name,
      label,
      fullWidth,
      disabled,
      defaultValue,
      control,

      dropDownValue,
      fieldError,
    ]
  );

  return (
    <div
      className={classNames(
        ' relative',
        fullWidth ? 'w-full' : 'w-60',
        className
      )}>
      {label && (
        <Typography color="textMuted" variant="h5" className="mb-2">
          {label}
          {required && <span className="mx-1 text-error-dark">*</span>}
        </Typography>
      )}
      {control ? (
        <Controller
          control={control as Control}
          name={name as string}
          render={({
            field: {
              onChange: controllerOnChange,
              value: controllerValue = [],
            },
          }) => getDropdown(controllerOnChange, controllerValue)}
        />
      ) : (
        getDropdown()
      )}
      {!disableHelperText && (
        <p
          style={{ minHeight: '1rem' }}
          className={classNames(
            'm-1 text-left text-sm text-background-contrastText ',
            {
              '!text-error-main': !!fieldError?.message,
            }
          )}>
          {(fieldError?.message as string) || ''}
        </p>
      )}
    </div>
  );
}
