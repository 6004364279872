import { isBefore, subYears } from 'date-fns';

type NoUndefinedField<T> = {
  [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>>;
};

export const NOT_ALLOWED_SUFFIXES = [
  'DDS',
  'Jr',
  'Sr',
  'Esq',
  'I',
  'II',
  'III',
  'Jr.',
  'Sr.',
  'Esq.',
  'IV',
  'JR',
  'SR',
  'ESQ',
  'PHD',
  'PHARMD',
  'RPH',
  'JR.',
  'SR.',
  'ESQ.',
  'V',
  'VI',
];

export function isOlderThan18Years(d: Date) {
  return isBefore(d, subYears(new Date(), 18));
}

export const removeUndefined = <T>(obj: T): NoUndefinedField<T> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(obj).reduce((acc: never, key: never) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }

    return acc;
  }, {}) as unknown as NoUndefinedField<T>;

export const formatSsn = (ssn = ''): string | undefined => {
  const ssnAsDigits = ssn.replace(/\D/g, ''); // Remove non-digits

  return ssnAsDigits
    .slice(0, 9)
    .replace(/^(\d{1,3})(\d{0,2})(\d{0,4})$/, (match, p1, p2, p3) =>
      [p1, p2 && `-${p2}`, p3 && `-${p3}`].filter(Boolean).join('')
    );
};
export const getQueryParamFromURL = ({
  name: _name,
  url = '',
}: {
  name: string;
  url: string;
}) => {
  const name = _name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function getReadableDate(isoDate: string, timeRequired = false) {
  const date = new Date(isoDate);
  const pad = (n: number, s = 2) => `${new Array(s).fill(0)}${n}`.slice(-s);
  const time = `, ${
    date.getHours() > 12
      ? Number(pad(date.getHours())) - 12
      : pad(date.getHours())
  }:${pad(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;

  return `${date.toLocaleString('en-us', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })} ${timeRequired ? time : ''} `;
}
export function normalizeString(s: string) {
  return s.replace(/\s+/g, '-').toLowerCase();
}
export function formatPhoneNumber(phoneNumberString?: string) {
  if (!phoneNumberString) {
    return '';
  }
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}

export const capitalizedString = (string: string) => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};
