import React from 'react';

import classNames from 'classnames';

import { ColumnObject, TableRowProps } from './types';

export function TableRow<TItem>({
  item,
  onRowClick,
  columns,
  isSticky,
  expandedAccordions = [],
  onAccordionExpandChange,
  getAccordionContent,
  rowIndex,
}: TableRowProps<TItem>): JSX.Element {
  const isAccordianSelected = expandedAccordions.some(
    (elem) => elem === rowIndex
  );
  const expandedContent = getAccordionContent
    ? React.cloneElement(getAccordionContent?.(item) || null, {
        isAccordianSelected,
      })
    : null;

  return (
    <>
      <tr
        onClick={() =>
          !getAccordionContent
            ? onRowClick?.(item)
            : onAccordionExpandChange?.(
                expandedAccordions.includes(rowIndex)
                  ? expandedAccordions.filter((elm) => elm !== rowIndex)
                  : [rowIndex]
              )
        }
        className={`${
          (onRowClick || onAccordionExpandChange) &&
          'cursor-pointer hover:bg-background-light'
        } border-b border-b-background-dark bg-background-main text-background-contrastText ${
          isAccordianSelected && '!bg-background-light'
        }  `}>
        {columns.map((column: ColumnObject<TItem>, index) => (
          <td
            key={column.title}
            className={classNames(
              ` whitespace-nowrap  px-6 py-3 text-md font-medium ${column?.classNames}`,
              {
                ' sticky bg-background-light':
                  isSticky && [columns.length - 1, 0, 1].includes(index),
                ' left-0 shadow-[20px_0px_rgba(38,38,38,1)]  ':
                  isSticky && index === 0,
                ' left-32   border-r border-zinc-500': isSticky && index === 1,
                ' right-[0.1px] border-l border-zinc-500 ':
                  isSticky && index === columns.length - 1,
              }
            )}>
            {item && column.render(item, rowIndex)}
          </td>
        ))}
      </tr>
      <tr className={classNames('overflow-hidden transition-all')}>
        <td
          colSpan={columns.length}
          className={classNames('m-0 border-0 p-0 ')}>
          {expandedContent}
        </td>
      </tr>
    </>
  );
}
