import React, { PropsWithChildren } from 'react';

import { Helmet } from 'react-helmet';

import LOGO from '../../../assets/images/LOGO.png';

type PageProps = {
  title?: string;
};
export function Page({ children, title }: PropsWithChildren<PageProps>) {
  return (
    <div>
      <Helmet>
        <title>{`${title}:  Brite live`}</title>
        <link rel="icon" type="image/png" href={LOGO} />
      </Helmet>
      <div>{children}</div>
    </div>
  );
}
