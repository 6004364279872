import React, { LegacyRef, useEffect, useRef, useState } from 'react';

import { CameraIcon, PlusIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Typography } from 'components/common/Typography/Typography';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';
import { InputFieldType } from 'types/common';
import { OnboardingProviderStepsType } from 'types/onboard';

import { useOnboardProviderFormContext } from './OnboardFormContext';

export function ProfilePicture() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const hiddenFileInput = useRef<HTMLInputElement>();

  const providerOnboardData = localStorage.getItem('providerOnboardSteps');
  const { updateFormState, formState } = useOnboardProviderFormContext();

  const [preview, setPreview] = useState<string>();
  const providerOnboardSteps: OnboardingProviderStepsType = {
    picture: null,
    basicInformation: null,
    npiDeaLicense: null,
    specialities: null,
    contactDetails: null,
    password: null,
  };

  useEffect(() => {
    if (!providerOnboardData) {
      localStorage.setItem(
        'providerOnboardSteps',
        JSON.stringify(providerOnboardSteps)
      );
      localStorage.setItem('onboardToken', token || '');
    }
    if (
      localStorage.getItem('onboardToken') &&
      localStorage.getItem('onboardToken') !== token
    ) {
      localStorage.removeItem('providerOnboardSteps');
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addProfilePictureMutation = useMutationWithToast(
    UserService.addProfilePicture
  );
  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (file) {
      addProfilePicture(file);
    }
  };
  const addProfilePicture = (file: File) => {
    if (!!file && file?.size > 2000000) {
      toast.error('Picture size should be less than 2MB');

      return;
    }

    if (file) {
      addProfilePictureMutation.mutate(
        { picture: file, token: token || '' },
        {
          onSuccess(data) {
            updateFormState('picture', data.data.profilePicture);
            setPreview(URL.createObjectURL(file));
          },
        }
      );
    }
  };

  return (
    <div className=" mt-4 flex  w-full flex-col items-center  ">
      {/* eslint-disable-next-line */}
      <div
        onClick={handleClick}
        className={classNames(
          ' 5 flex h-60 w-60   cursor-pointer items-center justify-center rounded-full bg-background-light hover:opacity-90'
          // {
          //   '!rounded-none !bg-transparent': !!preview,
          // }
        )}>
        {addProfilePictureMutation?.isLoading && (
          <LoadingSpinner className="absolute inset-auto  h-10 w-10 text-primary-light " />
        )}
        {preview || formState?.picture ? (
          <div
            className={classNames('', {
              '  ': !!preview,
            })}>
            <img
              alt=""
              src={formState.picture || ''}
              className=" h-60 w-60  rounded-full hover:opacity-80"
            />
          </div>
        ) : (
          <div
            className={classNames('relative ', {
              'opacity-10': addProfilePictureMutation?.isLoading,
            })}>
            <CameraIcon className="h-32 w-32 p-4 text-background-contrastText" />

            <PlusIcon className=" absolute right-2 bottom-3 h-10 w-10 rounded-full border-4 border-background-light bg-primary-light text-background-contrastText" />
          </div>
        )}
        <input
          type={InputFieldType.File}
          ref={hiddenFileInput as LegacyRef<HTMLInputElement>}
          onChange={handleChange}
          className="hidden"
          accept="image/* .heic"
        />
      </div>
      <Typography variant="h4" align="center" className=" mt-4 w-80 ">
        {`Please click on ${preview ? 'picture' : 'camera icon'} to ${
          preview ? 'change' : 'upload'
        }  your profile picture.
       `}
      </Typography>
      <Button
        disabled={addProfilePictureMutation.isLoading || !formState.picture}
        onClick={() => {
          if (!formState?.picture) {
            toast.error('Please upload a profile picture');
          } else {
            navigate({
              pathname: '../basic-information',
              search: `token=${token}`,
            });
          }
        }}
        size="large"
        className="mt-6 !px-12 ">
        Next
      </Button>
    </div>
  );
}
