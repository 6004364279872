import classNames from 'classnames';

import { Typography } from '../Typography/Typography';

interface CheckBoxProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  setRef?: (ref: HTMLInputElement | null) => void;
}

export function CheckBox({
  id,
  onChange,
  className,
  label,
  checked,
  setRef,
}: CheckBoxProps) {
  return (
    <div className={classNames(' flex items-center text-md   ', className)}>
      <input
        ref={(ref) => setRef?.(ref)}
        className="h-6 w-4  cursor-pointer accent-primary-main  "
        id={id}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        type="checkbox"
        checked={checked}
      />
      <span className="mr-2" />
      <label htmlFor={id}>
        <Typography
          className="whitespace-nowrap"
          color="textMuted"
          variant="subtitle2">
          {label}
        </Typography>
      </label>
    </div>
  );
}
