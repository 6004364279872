import { differenceInYears } from 'date-fns';
import * as yup from 'yup';

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
});

const phone = {
  phone: yup
    .string()
    .required('Please enter phone number')
    .min(10, 'Please enter valid phone')
    .max(13, 'Please enter valid phone')
    .typeError('Phone should be number'),
};

export const phoneSchema = yup.object().shape({ ...phone });

export const loginCredSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  password: yup.string().min(8).max(32).required('Please enter password'),
});

export const invitePatientSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter email'),
  name: yup.string().max(32).required('Please enter name'),
});

export const resetPasswordSchema = yup.object({
  password: yup.string().required('Please enter your new password.'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

export const updatePasswordSchema = yup.object({
  oldPassword: yup.string().required('Please enter your old password.'),
  newPassword: yup.string().required('Please enter your new password.'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('newPassword')], 'Your passwords do not match.'),
});

const address = {
  addressLine: yup.string().required('Please enter address.'),
  city: yup.string().required('Please enter city.'),
  country: yup.string().required('Please enter country.'),
  state: yup.string().required('Please enter state.'),
  zipCode: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .required('Please enter zip code.')
    .typeError('Zip Code should be number'),
};

export const addressSchema = yup.object({
  ...address,
});

export const providerBasicInformationSchema = yup.object().shape({
  providerTitle: yup.string().max(32).required('Please select title'),
  middleName: yup.string().nullable(true),
  firstName: yup
    .string()
    .min(3, 'Please enter valid first name')
    .max(32)
    .required('Please enter first name'),
  lastName: yup
    .string()
    .min(3, 'Please enter valid last name')
    .max(32)
    .required('Please enter last name'),
  gender: yup.string().max(32).required('Please select gender'),

  dob: yup.string().required('Please select your DOB').nullable(false),
  // ...phone,
});

export const providerSpecialitiesSchema = yup.object().shape({
  specialities: yup
    .array()
    .min(1, 'Please select specialty ')
    .required('Please select specialty '),
});

export const providerNPISchema = yup.object().shape({
  npi: yup
    .string()
    .required('Please enter NPI number')
    .min(10, 'Please enter 10 digit NPI number')
    .max(10, 'Please enter 10 digit NPI number'),
});

export const providerContactDetailsSchema = yup.object().shape({
  officeManagerName: yup.string().max(32).required('Please enter manager name'),
  officeManagerEmail: yup
    .string()
    .email('Please enter valid email')
    .required('Please enter manager email'),
  ...phone,
});
export const providerPasswordSchema = yup.object().shape({
  password: yup.string().min(8).max(32).required('Please enter password'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

export const providerOnboardSchema = yup.object().shape({
  password: yup.string().min(8).max(32).required('Please enter password'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});
export const adminOnboardSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(32, 'First name must not be more than 32 characters.')
    .required('Please enter first name'),
  lastName: yup
    .string()
    .max(32, 'Last name must not be more than 32 characters.')
    .required('Please enter last name'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters.')
    .max(32, 'Password must not be more than 32 characters.')
    .required('Please enter password'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  ...phone,
});

export const pharmacyUserOnboardSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(32, 'First name must not be more than 32 characters.')
    .required('Please enter first name'),
  lastName: yup
    .string()
    .max(32, 'Last name must not be more than 32 characters.')
    .required('Please enter last name'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters.')
    .max(32, 'Password must not be more than 32 characters.')
    .required('Please enter password'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  ...phone,
});

export const practiceAdminOnboardSchema = yup.object().shape({
  firstName: yup.string().max(32).required('Please enter first name'),
  lastName: yup.string().max(32).required('Please enter last name'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters.')
    .max(32, 'Password must not be more than 32 characters.')
    .required('Please enter password'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  ...phone,
});
export const patientOnboardSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(3, 'First Name must be at least 3 characters ')
    .max(32)
    .required('Please enter first name'),
  lastName: yup
    .string()
    .min(3, 'Last Name must be at least 3 characters ')
    .max(32)
    .required('Please enter last name'),
  ...phone,

  password: yup.string().min(8).max(32).required('Please enter password'),
  confirmPassword: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  emergencyContactName: yup
    .string()
    .min(3, 'Emergency contact name must be at least 3 characters ')
    .max(32)
    .required('Please enter emergency contact name'),
  emergencyContactNumber: yup
    .string()
    .required('Please enter emergency contact number')
    .min(10, 'Please enter valid phone')
    .max(13, 'Please enter valid phone')
    .typeError('Phone should be number'),
  gender: yup.string().max(32).required('Please select gender'),
  married: yup.string().max(32).required('Please select your status'),
  dob: yup
    .string()
    .required('Please select your DOB')
    .test(
      'dob',
      'Age must be between 18 and 100 years',
      (value) => differenceInYears(new Date(), new Date(value ?? '')) >= 18
    ),
  showDriverLicense: yup
    .string()
    .oneOf(['Yes', 'No'], 'Please select a valid option ("Yes" or "No")'),

  licenseNumber: yup.string().when('showDriverLicense', {
    is: 'Yes',
    then: yup.string().max(32).required('Please enter license number'),
  }),
  expDate: yup.string().when('showDriverLicense', {
    is: 'Yes',
    then: yup
      .string()
      .required('Please select license expiration date')
      .nullable(false),
  }),
  stateId: yup.string().when('showDriverLicense', {
    is: 'Yes',
    then: yup.string().max(32).required('Please select license state'),
  }),
  ssn: yup.string().when('showDriverLicense', {
    is: 'No',
    then: yup
      .string()
      .matches(/^(\d{3})-(\d{2})-(\d{4})$/, {
        message: 'Social security number must be 9 digits',
      })
      .required('Please enter social security number'),
  }),
});
export const deaAndLicenseSchema = (type: string) =>
  yup.object({
    ...(type === 'DEA' && {
      dea: yup
        .string()
        .trim()
        .matches(/[a-zA-Z]{2}\d{7}$/, 'Please enter valid DEA number.')
        .required('Please enter DEA number'),
    }),
    ...(type === 'License' && {
      license: yup.string().max(32).required('Please enter License number'),
    }),
    stateId: yup.string().max(32).required('Please select state'),
  });

export const deaSchema = () =>
  yup.object().shape({
    dea: yup
      .string()
      .trim()
      .matches(/[a-zA-Z]{2}\d{7}$/, 'Please enter valid DEA number.')
      .required('Please enter DEA number'),

    deaExpiry: yup
      .date()
      .required('Please select an expiration Date')
      .transform((value, originalValue) => {
        const parsedDate = Date.parse(originalValue);

        // eslint-disable-next-line no-restricted-globals
        return isNaN(parsedDate) ? new Date() : new Date(parsedDate);
      })
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'The expiration date must be in the future'
      ),
  });

export const addDeaAndLicenseSchema = () =>
  yup.object({
    license: yup.string().max(32).required('Please enter license number'),

    stateId: yup.string().max(32).required('Please select state'),

    licenseExpiry: yup
      .date()
      .required('Please select an expiration Date')
      .transform((value, originalValue) => {
        const parsedDate = Date.parse(originalValue);

        // eslint-disable-next-line no-restricted-globals
        return isNaN(parsedDate) ? new Date() : new Date(parsedDate);
      })
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'The expiration date must be in the future'
      ),
  });

export const supervisingDoctorSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(32)
    .required('Please enter first name')
    .min(3, 'Please enter valid name'),
  lastName: yup
    .string()
    .max(32)
    .required('Please enter last name')
    .min(3, 'Please enter valid name'),
  suffix: yup.string().max(32).required('Please select title'),
  supervisingDea: yup
    .string()
    .trim()
    .matches(/[a-zA-Z]{2}\d{7}$/, 'Please enter valid DEA number.')
    .required('Please enter DEA number'),
  licenseNumber: yup.string().required('Please enter license number'),
  licenseState: yup.string().max(32).required('Please select license state'),
  middleName: yup.string().max(32).nullable(),
  npi: yup
    .string()
    .matches(/^\d{10}$/, 'NPI should be exactly 10 digits')
    .required('Please enter NPI number'),
});
