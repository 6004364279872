import classNames from 'classnames';

import { TableHeadItemProps } from './types';

export function TableHeadItem<TItem>({
  column,
  isSticky,
  columnIndex,
}: TableHeadItemProps<TItem>): JSX.Element {
  return (
    <th
      scope="col"
      className={classNames(
        `z-1  whitespace-nowrap  py-3 px-6 text-md   ${
          column?.classNames || ''
        }`,
        {
          ' sticky bg-primary-main ': isSticky,
          ' left-0 shadow-[10px_-15px_0px_15px_rgba(53,149,131,1)]':
            columnIndex === 0,
          ' left-32 ': columnIndex === 1,
        }
      )}>
      {column.title}
    </th>
  );
}
