import React, { useEffect } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

export function Logout() {
  const location = useLocation();

  useEffect(() => {
    localStorage.removeItem('login_redirect');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Navigate to="/login" state={{ from: location }} replace />;
}
