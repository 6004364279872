import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// eslint-disable-next-line import/extensions
import LogoWhite from 'assets/images/White Logo.png';
import { ErrorFallbackComponent } from 'components/general/ErrorFallbackComponent/ErrorFallbackComponent';

import { PatientApp } from './patient-app/PatientApp';
import { PatientLogin } from './patient-login/PatientLogin';
import { PatientOnboard } from './patient-onboard/PatientOnboard';
import { PrivacyPolicy } from './privacy-policy/PrivacyPolicy';

function AuthLayout() {
  const redirectToUrl = localStorage.getItem('login_redirect');
  const location = useLocation();

  if (location?.pathname === '/' || location?.pathname === '') {
    return <Navigate to="./login" />;
  }
  if (location?.pathname.includes('/patient/onboard')) {
    return <PatientOnboard />;
  }
  if (location.pathname.includes('/privacy-policy')) {
    return <PrivacyPolicy />;
  }

  if (location?.pathname.includes('patient-login')) {
    return <PatientLogin />;
  }
  if (location?.pathname.includes('patient-app')) {
    return <PatientApp />;
  }
  if (
    location?.pathname.includes('/verify-email') ||
    location?.pathname.includes('/provider/onboard')
  ) {
    return <Outlet />;
  }
  if (
    !location?.pathname.includes('/logout') &&
    !location?.pathname.includes('/onboard') &&
    redirectToUrl
  ) {
    window.location.replace(redirectToUrl);

    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div className="hidden  min-h-screen w-1/2 bg-background-main md:flex">
        <div className="flex h-full w-full items-center justify-center">
          <img src={LogoWhite} alt="Logo" style={{ maxHeight: '100vh' }} />
        </div>
      </div>
      <div className="relative grid min-h-screen w-full min-w-[35rem] place-items-center bg-background-dark md:w-1/2">
        <div
          className={classNames(
            'w-full rounded-md border-background-contrastText bg-background-main shadow-md',
            {
              'max-w-md':
                !location?.pathname.includes('/admin/onboard') &&
                !location?.pathname.includes('/staff/onboard') &&
                !location?.pathname.includes('/practice/onboard') &&
                !location?.pathname.includes('/pharmacy/onboard'),
            }
          )}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

const BoundedAuthLayout = Sentry.withErrorBoundary(AuthLayout, {
  fallback: ErrorFallbackComponent,
});

export { BoundedAuthLayout as AuthLayout };
