import { useState } from 'react';

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';

import { Table } from 'components/common/Table/Table';
import { Typography } from 'components/common/Typography/Typography';
import { QueryKeys } from 'constants/query-keys';
import { UserService } from 'services/user';
import { GetDeaLicenses, SupervisingProvider } from 'types/onboard';

import { AddLicenseDeaModal } from '../AddLicenseDea/AddLicenseDeaModal';
import { useDeaLicenseContext } from '../AddLicenseDea/DeaLicensesContext';
import { SupervisingProviderListingModal } from './SupervisingProvidersListingModal/SupervisingProviderListingModal';

export function DEALicenseListing() {
  const getStatesQuery = useQuery([QueryKeys.States], () =>
    UserService.getStates()
  );

  const states = getStatesQuery.data?.data;

  const [supervisingProviderData, setSupervisingProviderData] =
    useState<SupervisingProvider>();
  const [supervisingProviderState, setSupervisingProviderState] = useState('');
  const [
    showSupervisingProviderListingModal,
    setShowSupervisingProviderListingModal,
  ] = useState(false);
  const [showAddDeaLicenseModal, setShowAddDEALicenseModal] = useState(false);
  const [clickedDeaLicenseData, setClickedDeaLiceneData] =
    useState<GetDeaLicenses>();
  const [actionType, setActionType] = useState<'update' | 'clone'>();
  const [updateStateId, setUpdateStateId] = useState<string | undefined>();

  const { deaLicenses, handleDEALicenseData, handleDeleteDEALicenseData } =
    useDeaLicenseContext();

  const handleData = (data: GetDeaLicenses) => {
    handleDEALicenseData(data);
  };

  const handleDelete = (id: string) => {
    handleDeleteDEALicenseData(id, deaLicenses);
  };

  return (
    <div>
      <Table
        // loading={DEALicenseData?.isLoading}
        rowsData={deaLicenses || []}
        columns={[
          {
            title: 'License',
            render: (rowData) => rowData.license,
            classNames: 'text-center',
          },

          {
            title: 'DEA',
            render: (rowData) => rowData.dea || '-',
            classNames: 'text-center',
          },

          {
            title: 'State',
            render: (rowData) =>
              rowData.state
                ? rowData.state
                : rowData.stateId
                ? states?.find((item) => item.id === rowData.stateId)?.name ||
                  ''
                : '-',
            classNames: 'text-center',
          },
          {
            title: 'Supervising Provider',
            classNames: 'text-center',
            render: (rowData) =>
              rowData.supervisingProvider &&
              rowData?.supervisingProvider?.licenseNumber ? (
                <Typography
                  variant="subtitle3"
                  color="primary"
                  className="cursor-pointer justify-center underline"
                  onClick={() => {
                    setSupervisingProviderState(rowData.state);
                    if (rowData.supervisingProvider) {
                      setSupervisingProviderData(rowData.supervisingProvider);
                    }
                    setShowSupervisingProviderListingModal(true);
                  }}>
                  View Details
                </Typography>
              ) : (
                <Typography variant="subtitle3" className="justify-center">
                  Not Required
                </Typography>
              ),
          },
          {
            title: '',
            render: (rowData) => (
              <div className="flex">
                <div
                  className="rounded border border-primary-main p-1.5"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setUpdateStateId(rowData.stateId);
                    setClickedDeaLiceneData({
                      ...rowData,
                      stateId: '',
                      state: '',
                    });
                    setActionType('clone');
                    setShowAddDEALicenseModal(true);
                  }}
                  onKeyDown={() => {
                    //
                  }}>
                  <Typography variant="subtitle3">
                    Copy for another state
                  </Typography>
                </div>
              </div>
            ),
          },
          {
            title: '',
            render: (rowData) => (
              <div className="flex">
                <PencilIcon
                  className=" text-white-400 ml-1 h-4 cursor-pointer"
                  onClick={() => {
                    setClickedDeaLiceneData(rowData);
                    setShowAddDEALicenseModal(true);
                    setUpdateStateId(rowData.stateId);
                    setActionType('update');
                  }}
                />
                <span className="mx-2" />
                <TrashIcon
                  className="text-white-400 ml-1 h-4 cursor-pointer"
                  onClick={() => {
                    handleDelete(rowData.stateId);
                  }}
                />
              </div>
            ),
          },
        ]}
        noDataMessage=" You currently have no records"
      />

      <SupervisingProviderListingModal
        supervisingProvider={supervisingProviderData}
        supervisingProviderState={supervisingProviderState}
        showSupervisingProviderListingModal={
          showSupervisingProviderListingModal
        }
        setShowSupervisingProviderListingModal={
          setShowSupervisingProviderListingModal
        }
      />

      <AddLicenseDeaModal
        actionType={actionType}
        updationStateId={updateStateId}
        deaLicenseData={clickedDeaLicenseData}
        showAddModal={showAddDeaLicenseModal}
        handleClose={(state: boolean) => {
          setShowAddDEALicenseModal(state);
          setUpdateStateId(undefined);
        }}
        handleDEALicenseData={handleData}
      />
    </div>
  );
}
