import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useSearchParams } from 'react-router-dom';

// eslint-disable-next-line
import Logo from 'assets/images/LOGO.png';
import { Button } from 'components/common/Button/Button';
import { LoadingSpinner } from 'components/common/Loading/LoadingSpinner';
import { Typography } from 'components/common/Typography/Typography';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';

type StatusProperties = {
  logo: ReactElement;
  title: string;
  message: ReactElement | string;
};

type StatusTypes = 'loading' | 'verified' | 'error';

type Status = {
  [Key: string]: StatusProperties;
};

export function VerifyEmail() {
  const [active, setActive] = useState<StatusTypes>('loading');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const verifyEmailMutation = useMutationWithToast(UserService.verifyEmail, {
    onError() {
      setActive('error');
    },
  });

  const verifyEmail = useCallback(() => {
    verifyEmailMutation.mutate(
      { token: token ?? '' },
      {
        onSuccess: () => {
          setActive('verified');
        },
        onError: () => {
          setActive('error');
        },
      }
    );
  }, [token, verifyEmailMutation]);

  useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line
  }, []);

  const status: Status = {
    loading: {
      logo: <LoadingSpinner className="mt-2 text-primary-main" size={12} />,
      title: 'Verifying Email',
      message: (
        <Typography variant="h4">
          We are verifying your email. Thank you for your patience.
        </Typography>
      ),
    },
    verified: {
      logo: <CheckBadgeIcon className="mt-2 text-primary-main" height={62} />,
      title: 'Email Verified!',
      message: (
        <div className="flex flex-col items-center">
          <Typography variant="h4"> Thank you for Verification.</Typography>
          <Button
            onClick={() => window.location.replace('/login')}
            className="mt-2">
            Login
          </Button>
        </div>
      ),
    },
    error: {
      logo: (
        <ExclamationCircleIcon className="mt-2 text-error-main" height={52} />
      ),
      title: 'Verification failed!',
      message: (
        <div className="flex flex-col">
          <Typography align="center" variant="h4">
            Something went wrong. We are unable to verify your email.
            <br />
            Please try again later.
          </Typography>
          <br />
          <Button
            onClick={() => window.location.replace('/login')}
            className="mx-auto mt-1">
            Login
          </Button>
        </div>
      ),
    },
  };

  return (
    <div className="flex h-screen w-screen justify-center bg-background-dark  ">
      <div className="shadow-black-900 mt-40 h-72 w-2/5 rounded border border-background-contrastText bg-background-main shadow-md  ">
        <div className="relative flex flex-col items-center justify-center">
          <div className="absolute -top-9 rounded-full bg-background-dark p-3  ">
            <img className="w-12" src={Logo} alt="logo" />
          </div>
          {/* <Typography className="mt-10" color="textMuted" variant="h1">
            Brite Live
          </Typography> */}
          <div className="mt-12 flex flex-col items-center">
            {status[active].logo}
            <Typography className="mt-3" color="textMuted" variant="h3">
              {status[active].title}
            </Typography>
            <Typography
              className="mt-3"
              color="textMuted"
              align="center"
              variant="subtitle3">
              {status[active].message}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
