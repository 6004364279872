import { createBrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from 'components/common/ErrorBoundary/ErrorBoundary';
import { Page } from 'components/common/page/Page';
import { Page404 } from 'components/general/misc/Page404';
import { BasicInformation } from 'components/ProviderOnboard2/BasicInformation';
import { ContactDetails } from 'components/ProviderOnboard2/ContactDetails';
import { CreatePassword } from 'components/ProviderOnboard2/CreatePassword';
import { NPIDEAAndLicense } from 'components/ProviderOnboard2/NPIDEAAndLicenses/NPIDEAAndLicenses';
import { ProfilePicture } from 'components/ProviderOnboard2/ProfilePicture';
import { Specialties } from 'components/ProviderOnboard2/Specialties';
import { AdminOnboard } from 'pages/auth/admin-onboard/AdminOnboard';
import { AuthLayout } from 'pages/auth/AuthLayout';
import { ForgotPassword } from 'pages/auth/forgot-password/ForgotPassword';
import { Login } from 'pages/auth/login/Login';
import { Logout } from 'pages/auth/logout/logout';
import { PatientLogin } from 'pages/auth/patient-login/PatientLogin';
import { PatientOnboard } from 'pages/auth/patient-onboard/PatientOnboard';
import { PharmacyUserOnboard } from 'pages/auth/pharmacy-user-onboard/PharmacyUserOnboard';
import { PrivacyPolicy } from 'pages/auth/privacy-policy/PrivacyPolicy';
import { ProviderOnboard2 } from 'pages/auth/provider-onboard/ProviderOnboard2';
import { ResetPassword } from 'pages/auth/reset-password/ResetPassword';
import { StaffOnboard } from 'pages/auth/staff-onboard/StaffOnboard';
import { VerifyEmail } from 'pages/auth/verify-email/VerifyEmail';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <AuthLayout />
      </ErrorBoundary>
    ),
    errorElement: <Page404 />,
    children: [
      {
        path: 'login',
        element: (
          <ErrorBoundary>
            <Page title="Login">
              <Login />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'logout',
        element: (
          <ErrorBoundary>
            <Page title="Login">
              <Logout />
            </Page>
          </ErrorBoundary>
        ),
      },

      {
        path: 'login/:login_token',
        element: (
          <ErrorBoundary>
            <Page title="Login">
              <ForgotPassword />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <ErrorBoundary>
            <Page title="Forgot password">
              <ForgotPassword />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <ErrorBoundary>
            <Page title="Reset password">
              <ResetPassword />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'verify-email',
        element: (
          <ErrorBoundary>
            <Page title="Verify email">
              <VerifyEmail />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'patient/onboard',
        element: (
          <ErrorBoundary>
            <Page title="Onboard Patient">
              <PatientOnboard />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'provider/onboard',
        element: (
          <ErrorBoundary>
            <Page title="Onboard Provider">
              <ProviderOnboard2 />
            </Page>
          </ErrorBoundary>
        ),
        children: [
          {
            path: 'profile-picture',
            element: <ProfilePicture />,
          },
          {
            path: 'basic-information',
            element: <BasicInformation />,
          },
          {
            path: 'specialties',
            element: <Specialties />,
          },
          {
            path: 'npi-dea-license',
            element: <NPIDEAAndLicense />,
          },
          {
            path: 'contact-details',
            element: <ContactDetails />,
          },
          {
            path: 'create-password',
            element: <CreatePassword />,
          },
        ],
      },
      {
        path: 'admin/onboard',
        element: (
          <ErrorBoundary>
            <Page title="Onboard Admin">
              <AdminOnboard />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'staff/onboard',
        element: (
          <ErrorBoundary>
            <Page title="Onboard Staff">
              <StaffOnboard />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'pharmacy/onboard',
        element: (
          <ErrorBoundary>
            <Page title="Onboard Pharmacy User">
              <PharmacyUserOnboard />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'patient-login',
        element: (
          <ErrorBoundary>
            <Page title="Patient Login">
              <PatientLogin />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'patient-app',
        element: (
          <ErrorBoundary>
            <Page title="Patient Login">
              <PatientLogin />
            </Page>
          </ErrorBoundary>
        ),
      },
      {
        path: 'privacy-policy',
        element: (
          <ErrorBoundary>
            <Page title="Privacy Policy">
              <PrivacyPolicy />
            </Page>
          </ErrorBoundary>
        ),
      },
    ],
  },
]);
