import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { useFloating } from '@floating-ui/react-dom';
import { Portal } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Control, Controller } from 'react-hook-form';

import { ThemeName } from 'theme/types';
import { InfiniteScrollProps } from 'types/common';
import { ControllerOnChangeType } from 'types/inputTypes';

import { LoadingSpinner } from '../Loading/LoadingSpinner';
import { SearchInput } from '../SearchInput/SearchInput';
import { Typography } from '../Typography/Typography';

interface Option {
  // eslint-disable-next-line
  title: string;
  // eslint-disable-next-line
  value: string | number;
}

type Size = 'small' | 'medium' | 'large';

const sizeClasses: { [S in Size]: string } = {
  small: 'px-4 py-[0.4rem] text-md',
  medium: 'px-2 py-3 text-md',
  large: 'px-3 py-3.5 text-lg',
};

type ControlProps =
  | {
      // eslint-disable-next-line
      control?: Control<any, any>;
      name: string;
    }
  | {
      control?: false | undefined;
      name?: never;
    };
type DropDownProps = {
  size?: Size;
  options: Option[] | undefined;
  label?: string;
  defaultValue?: Option;
  searchable?: boolean;
  onChange?: (value: Option) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  helperText?: string;
  disableHelperText?: boolean;
  error?: boolean;
  theme?: ThemeName;
  clearDropdownState?: boolean;
  required?: boolean;
  placeholder?: string;
  onSearchChange?: (text: string) => void;
} & ControlProps &
  InfiniteScrollProps;
export function DropDown({
  size = 'medium',
  label,
  options,
  defaultValue,
  searchable = false,
  onChange,
  fullWidth,
  disabled,
  className,
  control,
  name,
  helperText,
  disableHelperText,
  error,
  placeholder,
  isOptionsLoading,
  withInfiniteScroll,
  isFetchingNextPage,
  onSearchChange,
  required,
  onNextPage,
  clearDropdownState,
  theme = ThemeName.Dark,
}: DropDownProps) {
  const [searchText, setSearchText] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const { x, y, refs } = useFloating();

  const onDropdownScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (!withInfiniteScroll || isFetchingNextPage) return;
      const target = event.currentTarget;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
        onNextPage?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetchingNextPage]
  );

  const closeMenu = useCallback((event: MouseEvent) => {
    if (
      (event?.target as SVGElement).id === 'DropDownbutton' ||
      (event?.target as SVGElement).id === 'searchInput'
    ) {
      return;
    }
    setOpen(false);
  }, []);

  const getError = useCallback(() => {
    if (control) {
      if (control._formState.errors) {
        if (name.includes('.')) {
          const splittedArray = name.split('.');

          return splittedArray.reduce(
            // eslint-disable-next-line
            (acc: any, i) => acc?.[i],
            control._formState.errors
          );
        }

        return control._formState.errors?.[name];
      }
    }

    return undefined;
  }, [control, name]);

  const fieldError = getError();

  useLayoutEffect(() => {
    if (!clearDropdownState) return;
    setValue('');
  }, [clearDropdownState]);

  useEffect(() => {
    document.addEventListener('click', (e) => closeMenu(e));

    return () => document.removeEventListener('click', closeMenu);
  }, [closeMenu]);

  const getDropdown = useCallback(
    (controllerOnChange?: ControllerOnChangeType) => (
      <div id="DropDownbutton">
        {/*  eslint-disable-next-line */}
        <div
          id="DropDownbutton"
          onClick={() => setOpen(!open)}
          className={classNames(
            ` flex w-full cursor-pointer items-center justify-between rounded bg-background-light text-zinc-400 ${sizeClasses[size]}`,
            open ? 'rounded-b-none' : 'rounded',
            disabled
              ? 'pointer-events-none cursor-no-drop opacity-80'
              : 'cursor-pointer',
            {
              'border border-error-main': !!error || !!fieldError,
              '!text-background-contrastText': !!value || !!defaultValue?.title,
            }
          )}>
          {/*  eslint-disable-next-line */}
          <div
            id="DropDownbutton"
            onClick={() => setOpen(!open)}
            className="max-w-[90%] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {value || (defaultValue?.title ?? placeholder)}
          </div>
          <ChevronDownIcon
            id="DropDownbutton"
            height={20}
            className={`${open && 'rotate-180'}`}
          />
        </div>
        {open && (
          <Portal>
            <div
              onScroll={onDropdownScroll}
              ref={refs.setFloating}
              style={{
                position: 'absolute',
                top: y ?? 0,
                left: x ?? 0,
                width: refs.reference.current?.getBoundingClientRect().width,
              }}
              className={classNames(
                theme,
                'z-[999] -mt-4 max-h-48  overflow-y-auto rounded-b bg-background-light',
                {
                  '!mt-1': disableHelperText,
                }
              )}>
              {searchable && (
                <SearchInput
                  id="searchInput"
                  onSearch={(v) =>
                    onSearchChange ? onSearchChange(v) : setSearchText(v)
                  }
                />
              )}
              <ul
                className={classNames('p-2 pt-1', {
                  'border-t border-t-zinc-400': searchable,
                })}>
                {isOptionsLoading ? (
                  <li className="flex items-center space-x-3 p-2 py-1 pl-3.5 pt-2 text-md text-background-contrastText">
                    <LoadingSpinner className="h-[1.5rem] w-[1.5rem]" />{' '}
                    <Typography variant="subtitle2">loading...</Typography>
                  </li>
                ) : options?.length ? (
                  options.map(({ title, value: v }: Option) => (
                    <>
                      {/* eslint-disable-next-line */}
                      <li
                        key={title}
                        onClick={
                          control
                            ? () => {
                                setValue(title);
                                if (controllerOnChange) {
                                  controllerOnChange({
                                    target: {
                                      value: v,
                                      name: name ?? '',
                                    },
                                  });
                                }
                                setOpen(false);
                              }
                            : () => {
                                if (onChange) {
                                  setValue(title);
                                  onChange({ title, value: v });
                                }
                                setOpen(false);
                              }
                        }
                        className={classNames(
                          'block cursor-pointer p-2 pl-4 text-base text-background-contrastText hover:rounded-sm hover:bg-primary-main hover:text-primary-contrastText',
                          {
                            ' border-l-4 border-primary-main pl-3 ':
                              title.toLowerCase() === value?.toLowerCase(),
                            ' hidden ':
                              searchable &&
                              !title
                                .toLowerCase()
                                .startsWith(searchText.toLowerCase()),
                          }
                        )}>
                        {title}
                      </li>
                    </>
                  ))
                ) : (
                  <li className="p-2 py-1 pt-2 text-md text-background-contrastText">
                    Not Found
                  </li>
                )}
                {isFetchingNextPage && (
                  <li className="flex items-center space-x-3 p-2 py-1 pl-3.5 pt-2 text-md text-background-contrastText">
                    <LoadingSpinner className="h-[1.5rem] w-[1.5rem]" />{' '}
                    <Typography variant="subtitle2">loading...</Typography>
                  </li>
                )}
              </ul>
            </div>
          </Portal>
        )}
      </div>
    ),
    [
      isFetchingNextPage,
      onDropdownScroll,
      isOptionsLoading,
      placeholder,
      fieldError,
      disableHelperText,
      size,
      value,
      searchable,
      searchText,
      options,
      open,
      onChange,
      name,
      refs.reference,
      disabled,
      defaultValue,
      control,
      error,

      theme,
      onSearchChange,
      refs.setFloating,
      x,
      y,
    ]
  );

  return (
    <div
      ref={refs.setReference}
      className={classNames(
        ' relative',
        fullWidth ? 'w-full' : 'w-60',
        className
      )}>
      {label && (
        <label htmlFor={name}>
          <Typography className="mb-1 opacity-80" variant="h5">
            {label}
            {required && <span className="mx-1 text-error-main">*</span>}
          </Typography>
        </label>
      )}
      {control ? (
        <Controller
          control={control as Control}
          name={name as string}
          render={({ field: { onChange: controllerOnChange } }) =>
            getDropdown(controllerOnChange)
          }
        />
      ) : (
        getDropdown()
      )}
      {!disableHelperText && (
        <p
          className={classNames(
            'm-1 h-[0.8rem] text-left  text-sm text-background-contrastText',
            {
              'h-[1rem] !text-error-main': !!error || !!fieldError,
            }
          )}>
          {helperText ?? (fieldError?.message as string)}
        </p>
      )}
    </div>
  );
}
