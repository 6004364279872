import React from 'react';

import classNames from 'classnames';

import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography/Typography';

type AgreementType = 'Emailing' | 'Studies' | 'Offerings' | 'WhyLicense';

type AgreementsProps = {
  type: AgreementType;
  onClose: () => void;
};

export function Agreements({ type, onClose }: AgreementsProps) {
  function getText() {
    if (type === 'Emailing') {
      return (
        <Typography>
          To facilitate prescriptions, BRITE requires you and your prescribing
          practitioners to provide relevant medical information as needed to
          accept and fulfill your prescriptions. We care about your privacy and
          will safeguard this information in accordance with HIPAA security
          requirements. We will only share this information, in a
          HIPAA-compliant fashion, with third-parties that require it for the
          direct and specific task of creating or fulfilling prescriptions for
          you, including BRITE Live physicians and its consultants and staff
          needed to support you and your prescribing practitioner.
          <br /> <br />
          We collect your email information in the event that we need to you
          directly about your prescription. If you do not wish to be contacted
          by email, please check here ___ and we will only contact you through
          the App or through your physician. highly personalized; determining
          optimal therapeutic dosages requires that a patient be followed and
          have regular access to their prescribing provider, particularly during
          the initial months as well as during periods of change as the optimal
          dosages are determined and tested. Our prescriptions are based on the
          costs of that particular product/pharmaceutical and will be charged on
          a per-prescription basis. Any additional pharmaceuticals are optional
          and up to you and/or your healthcare provider.
          <br /> <br />I have read and agree to the foregoing terms and
          conditions.
        </Typography>
      );
    }

    if (type === 'Studies') {
      return (
        <Typography>
          In the interest of furthering hormonal medicine, certain details of
          your treatment will be used to help us to better understand the value
          of hormonal treatments and the ways upon which standards of care can
          be improved. While your identity or medical information will only be
          shared with third parties as noted above, we may share aggregated
          medical data that does not include any identifying information in the
          interest of research or business development. By signing below you
          authorize your prescribing practitioner to share medical information
          with us and agree that we may share necessary information with your
          prescriber and pharmacist or de-identified data (data which is not
          connected to you) as described above. If you have any questions please
          contact us at support@brite.live.
        </Typography>
      );
    }

    if (type === 'Offerings') {
      return (
        <Typography>
          We may use your email address for occasional updates and marketing
          messages. We will not send you spam. You can always opt out by
          unsubscribing to an email or sending your request to
          support@brite.live
        </Typography>
      );
    }

    if (type === 'WhyLicense') {
      return (
        <div>
          <Typography variant="h5">
            Why do we ask for Driver&#39;s License ?
          </Typography>
          <Typography className="mt-2">
            Testosterone is a categorized as controlled substance in the same
            way that opiates are (we went to Washington asking for this to be
            changed, but it hasn&#39;t happened yet). We ask for this because it
            is required. We&#39;ll keep working to get the regulations changed!
          </Typography>
        </div>
      );
    }

    return '';
  }

  return (
    <div
      className={classNames(
        ' m-2 flex  w-full  flex-col items-center overflow-y-hidden '
      )}>
      {getText()}

      <Button onClick={onClose} className="mt-4 w-20">
        Close
      </Button>
    </div>
  );
}
