import classNames from 'classnames';

import { Typography } from '../Typography/Typography';

interface RadioButtonProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: (value: boolean | string) => void;
  className?: string;
  square?: boolean;
  fullWidth?: boolean;
}

export function RadioButton({
  id,
  onChange,
  className,
  label,
  checked,
  square,
  fullWidth,
}: RadioButtonProps) {
  function getRadioButton() {
    return square ? (
      // eslint-disable-next-line
      <div
        onClick={() => onChange(label)}
        className={classNames(
          ' flex  cursor-pointer items-center rounded-xl border border-zinc-300   py-3 px-4 ',
          {
            '!border-primary-main': checked,
          },
          {
            'w-full': fullWidth,
          }
        )}>
        {checked ? (
          <input
            className="h-4 w-4  cursor-pointer accent-primary-main  "
            id={id}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            type="checkbox"
            checked={checked}
          />
        ) : (
          <div
            className={classNames(
              '  h-4 w-4 rounded border-2  border-background-contrastText bg-background-contrastText ',
              {
                '!bg-primary-main': checked,
              }
            )}
          />
        )}
        <span className="mr-2" />
        <Typography
          className="capitalize"
          color="textMuted"
          variant="subtitle2">
          {label}
        </Typography>
      </div>
    ) : (
      <>
        <input
          className="h-10 w-4  cursor-pointer border-4 accent-primary-main  "
          id={id}
          onChange={(e) => onChange(e.target.checked)}
          type="radio"
          checked={checked}
        />
        <span className="mr-2" />
        <label htmlFor={id}>{label}</label>
      </>
    );
  }

  return (
    <div
      className={classNames(
        'flex  items-center rounded   text-md   ',
        className,
        {
          'w-full': fullWidth,
        }
      )}>
      {getRadioButton()}
    </div>
  );
}
