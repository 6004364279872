import React from 'react';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { Typography } from 'components/common/Typography/Typography';
import { pharmacyUserOnboardSchema } from 'constants/validation-schema';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { extractValidationErrors } from 'lib/general/extractors';
import { UserService } from 'services/user';
import { InputFieldType } from 'types/common';

export function PharmacyUserOnboard() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const pharmacyUserOnboardMutation = useMutationWithToast(
    UserService.onboardPharmacyUser,
    {}
  );
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(pharmacyUserOnboardSchema),
    defaultValues: {
      phone: '',
      firstName: '',
      lastName: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    pharmacyUserOnboardMutation.mutate(
      {
        token: token ?? '',
        pharmacyUserDetails: {
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          phone: `+${data.phone}`,
        },
      },
      {
        onSuccess: () => {
          navigate({ pathname: '/login' });
        },
      }
    );
  });

  return (
    <div className="min-h-screen w-full bg-background-dark p-24">
      <div className=" ">
        <Typography variant="h1">
          <span className="flex">
            Register as{' '}
            <Typography
              className="mx-2 inline-block"
              variant="h1"
              color="primary">
              Brite
            </Typography>{' '}
            Pharmacy User
          </span>
        </Typography>
        <Typography variant="h3" className="mt-2">
          Please provide us with the following information to register yourself.
        </Typography>
      </div>
      <div className="mt-12 grid gap-4">
        <div className="w-full">
          <div className="grid grid-cols-6">
            <div className="col-span-6 flex flex-col">
              <div className=" mt-4 flex justify-between">
                <TextInput
                  name="firstName"
                  type={InputFieldType.Text}
                  control={control}
                  helperText={
                    extractValidationErrors(
                      'firstName',
                      pharmacyUserOnboardMutation,
                      errors
                    ).msg
                  }
                  error={
                    extractValidationErrors(
                      'firstName',
                      pharmacyUserOnboardMutation,
                      errors
                    ).hasError
                  }
                  placeholder="First Name"
                  className=" "
                  fullWidth
                />
                <span className="mx-2" />
                <TextInput
                  name="lastName"
                  type={InputFieldType.Text}
                  control={control}
                  helperText={
                    extractValidationErrors(
                      'lastName',
                      pharmacyUserOnboardMutation,
                      errors
                    ).msg
                  }
                  error={
                    extractValidationErrors(
                      'lastName',
                      pharmacyUserOnboardMutation,
                      errors
                    ).hasError
                  }
                  placeholder="Last Name"
                  className=" "
                  fullWidth
                />
              </div>
              <div className=" mt-0 flex justify-between">
                <TextInput
                  type={InputFieldType.Password}
                  name="password"
                  control={control}
                  helperText={
                    extractValidationErrors(
                      'password',
                      pharmacyUserOnboardMutation,
                      errors
                    ).msg
                  }
                  error={
                    extractValidationErrors(
                      'password',
                      pharmacyUserOnboardMutation,
                      errors
                    ).hasError
                  }
                  placeholder="Password"
                  fullWidth
                />
                <span className="mx-2" />
                <TextInput
                  type={InputFieldType.Password}
                  name="confirmPassword"
                  control={control}
                  helperText={
                    extractValidationErrors(
                      'confirmPassword',
                      pharmacyUserOnboardMutation,
                      errors
                    ).msg
                  }
                  error={
                    extractValidationErrors(
                      'confirmPassword',
                      pharmacyUserOnboardMutation,
                      errors
                    ).hasError
                  }
                  placeholder="Confirm Password"
                  fullWidth
                />
              </div>
              <div className="flex items-center space-x-3">
                <TextInput
                  type={InputFieldType.Phone}
                  placeholder="Phone Number"
                  className="placeholder:text-zinc-400 "
                  fullWidth
                  name="phone"
                  control={control}
                  helperText={
                    extractValidationErrors(
                      'phone',
                      pharmacyUserOnboardMutation,
                      errors
                    ).msg
                  }
                  error={
                    extractValidationErrors(
                      'phone',
                      pharmacyUserOnboardMutation,
                      errors
                    ).hasError
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            loading={pharmacyUserOnboardMutation.isLoading}
            onClick={onSubmit}>
            Register <ArrowLongRightIcon className="ml-8 h-5 w-5 text-white" />
          </Button>
        </div>
      </div>
    </div>
  );
}
