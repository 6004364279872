import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Typography } from 'components/common/Typography/Typography';

import AppStoreLogo from '../../../assets/images/app-store.png';
import PlayStoreLogo from '../../../assets/images/play-store.png';
import LogoWhite from '../../../assets/images/White Logo.png';

export function PatientLogin() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const [logoUrl] = useState(searchParams.get('logo-url')?.toString());

  useEffect(() => {
    window.onpopstate = () => {
      navigate('/login');
    };
  }, [navigate]);

  return (
    <div className="flex h-screen w-screen justify-center bg-background-dark">
      <div className="mt-6 flex w-full flex-col items-center justify-center gap-1 p-4 sm:w-2/4">
        <Helmet>
          <meta name="apple-itunes-app" content="app-id=6448186350" />
          <meta name="google-play-app" content="app-id=com.brite.live" />
        </Helmet>
        {logoUrl ? (
          <img
            src={logoUrl !== 'undefined' ? (logoUrl as string) : LogoWhite}
            alt="Logo"
            className="h-28 overflow-hidden sm:h-40"
          />
        ) : (
          <img
            src={LogoWhite}
            alt="Logo"
            className="h-60 overflow-hidden sm:h-80"
          />
        )}
        {name && (
          <Typography
            color="primary"
            className="mt-4 text-2xl md:text-3xl lg:text-4xl"
            variant="h1">
            Hi {name}!
          </Typography>
        )}
        <Typography
          align="center"
          color="white"
          className="mt-6 text-xl md:text-2xl lg:text-3xl"
          variant="h1">
          Please download the app from app store or play store.
        </Typography>

        <Typography
          align="center"
          color="white"
          className="mt-6 text-2xl font-normal md:text-3xl lg:text-4xl"
          variant="h2">
          {name
            ? 'You will need to log in to the application using the credentials you just created.'
            : 'You will need to log in to the application using the same credentials.'}
        </Typography>

        <div className="flex items-center ">
          <div className="flex flex-col">
            <a
              href="https://apps.apple.com/in/app/brite-live/id6448186350"
              target="_blank"
              rel="noreferrer">
              <img className="f w-40 sm:w-60" src={AppStoreLogo} alt="Logo" />
            </a>
          </div>
          <div className="flex flex-col">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.brite.live"
              rel="noreferrer">
              <img className="f w-40 sm:w-60" src={PlayStoreLogo} alt="Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
